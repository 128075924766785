if (!quosal.api.crm)
    quosal.api.crm = {};
if (!quosal.api.crm.connectwise)
    quosal.api.crm.connectwise = {};

quosal.api.crm.connectwise.getOpportunityMetadata = function (additionalParameters) {
    return quosal.api.call('Crm.Connectwise.GetOpportunityMetadata', {
        additionalParameters: additionalParameters || {}
    });
};

quosal.api.crm.connectwise.saveOpportunity = function (idQuoteMain, params) {
    return quosal.api.call('Crm.Connectwise.SaveOpportunity', {
        idQuoteMain: idQuoteMain,
        params: params
    });
};

quosal.api.crm.connectwise.getOpportunities = function (criteria, pageNumber, pageSize, additionalParameters) {
    return quosal.api.call('Crm.Connectwise.GetOpportunities', {
        criteria: criteria,
        additionalParameters: additionalParameters || {}
    });
};

quosal.api.crm.connectwise.prepareForCwOpportunityDetail = function (idQuoteMain, groupingType) {
    return quosal.api.call('Crm.Connectwise.PrepareForCwOpportunityDetail', {
        idQuoteMain: idQuoteMain,
        groupingType: groupingType
    });
};
quosal.api.crm.connectwise.getOpportunityItems = function (idQuoteMain, groupingType) {
    return quosal.api.call('Crm.Connectwise.GetOpportunityItems', {
        idQuoteMain: idQuoteMain,
        groupingType: groupingType
    });
};
quosal.api.crm.connectwise.changeTabSettingsAndGetOpportunityItems = function (idQuoteMain, idQuoteTabs, cwType, idRecurringRevenue) {
    return quosal.api.call('Crm.Connectwise.GetOpportunityItems', {
        idQuoteMain: idQuoteMain,
        idQuoteTabs: idQuoteTabs,
        cwType: cwType,
        idRecurringRevenue: idRecurringRevenue
    });
};

quosal.api.crm.connectwise.getCustomers = function (criteria) {
    return quosal.api.call('Crm.GetCustomers', {
        firstName: criteria.firstName,
        lastName: criteria.lastName,
        accountName: criteria.accountName,
        orderby: criteria.orderby,
        offset: criteria.offset,
        count: criteria.count
    });
};

quosal.api.crm.connectwise.campaignSearch = function (campaignName) {
    return quosal.api.call('Crm.Connectwise.CampaignSearch', {
        campaignName: campaignName
    });
};
//step 2
quosal.api.crm.connectwise.getMenuEntry = function (caption) {
    return quosal.api.call('Crm.Connectwise.GetMenuEntry', {
        caption: caption
    });
}

quosal.api.crm.connectwise.createMenuEntry = function (id, caption, menulocation, link, locations) {
    return quosal.api.call('Crm.Connectwise.CreateMenuEntry', {
        id: id,
        caption: caption,
        menulocation: menulocation,
        link: link,
        locations: locations
    });
}

quosal.api.crm.connectwise.getInvoiceGroups = function () {
    return quosal.api.call('Crm.Connectwise.GetInvoiceGroups');
};