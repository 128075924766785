import {
	ATTACHED_DYNAMIC_QUESTION_MESSAGE,
	DELETE_BUTTON_LINKED_FIELD_MESSAGE,
	COMMON_GRID_PADDING,
	DIVIDER_STYLE,
	SECTION_NAME_CHARACTER_LIMIT,
	SECTION_NAME_LIMIT_HELPER_TEXT
} from "../../constants/createFormConstants";
import { Box, Grid } from "@mui/material";
import {
	CommonInputTextStyled,
	CustomWidthTooltip
} from "../../../../common/guidedSelling/styledComponents/commonStyled";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { QuestionLayout } from "./questionLayout";
import { QuestionButtonStyled, SectionGridStyled } from "../../styledComponents/createFormStyled";
import AddIcon from "@mui/icons-material/Add";

const getDeleteSectionTooltipTitle = (
	dqDependentQuesNumbers = [],
	quantityDependentQuesNumbers = []
) => {
	return dqDependentQuesNumbers.length > 0 && quantityDependentQuesNumbers.length > 0 ? (
		<>
			{ATTACHED_DYNAMIC_QUESTION_MESSAGE + " " + dqDependentQuesNumbers.join(",")}
			<br />
			{DELETE_BUTTON_LINKED_FIELD_MESSAGE + " " + quantityDependentQuesNumbers.join(",")}
		</>
	) : dqDependentQuesNumbers.length > 0 ? (
		ATTACHED_DYNAMIC_QUESTION_MESSAGE + " " + dqDependentQuesNumbers.join(",")
	) : quantityDependentQuesNumbers.length > 0 ? (
		DELETE_BUTTON_LINKED_FIELD_MESSAGE + " " + quantityDependentQuesNumbers.join(",")
	) : null;
};

export const SectionLayout = (props) => {
	const {
		sectionIndex,
		section: { name, questions },
		formActions,
		onDelete,
		sections,
		isSendable,
		theme,
		dependentQuestionNumbers: { dqDependentQuesNumbers, quantityDependentQuesNumbers }
	} = props;
	const deleteSectionTooltipTitle = getDeleteSectionTooltipTitle(
		dqDependentQuesNumbers,
		quantityDependentQuesNumbers
	);
	const isDeleteSectionDisabled =	 !(sections.length > 1) || Boolean(deleteSectionTooltipTitle);

	const handleErrForSectionName = (input) => !isSendable && input.length > 100;

	const handleErrMsgForSectionName = (input) => {
		if (!isSendable) {
			if (input.length > 100) {
				return SECTION_NAME_LIMIT_HELPER_TEXT;
			}
		}
	};

	return (
		<SectionGridStyled container pb={COMMON_GRID_PADDING}>
			<Grid item xs={12} p={COMMON_GRID_PADDING} sx={{ borderBottom: DIVIDER_STYLE }}>
				<CommonInputTextStyled
					error={handleErrForSectionName(name)}
					helperText={handleErrMsgForSectionName(name)}
					id={`section-name-input-section-${sectionIndex}`}
					inputProps={{ maxLength: SECTION_NAME_CHARACTER_LIMIT }}
					name="section-name"
					label="Section Name"
					placeholder="Section Name"
					value={name}
					onChange={(event) =>
						formActions[event.target.name](event.target.value, sectionIndex)
					}
				/>
			</Grid>
			{questions.map((question, questionIndex) => (
				<QuestionLayout
					theme={theme}
					isSendable={isSendable}
					questions={questions}
					onDelete={onDelete}
					key={"form-section-" + sectionIndex + "-question-" + questionIndex}
					question={question}
					questionIndex={questionIndex}
					sectionIndex={sectionIndex}
					formActions={formActions}
				/>
			))}
			<Grid
				container
				item
				xs={12}
				direction="row"
				justifyContent="space-between"
				sx={{ padding: "0", height: "fit-content", marginBottom: "8px" }}
			>
				<QuestionButtonStyled
					id={`createform-add-question-button`}
					endIcon={<AddIcon />}
					onClick={() => formActions["add-question"](sectionIndex)}
					sx={{ color: theme.palette.button_primary.main }}
				>
					Add Question
				</QuestionButtonStyled>
				<CustomWidthTooltip title={deleteSectionTooltipTitle}>
					<Box component="div">
						<QuestionButtonStyled
							id={`createform-delete-section-button`}
							disabled={isDeleteSectionDisabled}
							endIcon={<DeleteOutlineOutlinedIcon />}
							onClick={() =>
								onDelete("Section", () =>
									formActions["remove-section"](sectionIndex)
								)
							}
							sx={{ color: theme.palette.button_danger.secondary }}
						>
							Delete Section
						</QuestionButtonStyled>
					</Box>
				</CustomWidthTooltip>
			</Grid>
		</SectionGridStyled>
	);
};
