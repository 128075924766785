import React, { useCallback, useState } from "react";
import { Button, Menu, MenuItem, Stack, Typography } from "@mui/material";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import { useTheme } from "@mui/material/styles";
import {
	OpenInNew,
	PictureAsPdfOutlined
} from "@mui/icons-material";
import { useQuoteContext, useUserContext, useUserSettingsContext } from "../context";
import { useDocumentPreview } from "../customHooks";

export const QuoteHeaderMenu = ({
	displayEditCustomerDetails,
	hidePdfViewButton
}) => {
	const theme = useTheme();
	const { IsReadOnly } = useUserContext();
	const { IsLocked } = useQuoteContext();
	const { toggleDocumentPreviewer, documentPreviewEnabled } = useDocumentPreview();
	const userSettings = useUserSettingsContext();
	const [anchorEl, setAnchorEl] = useState(null);

	const menus = [
		{
			id: "EDIT_CUSTOMER_DETAILS",
			name: "Edit Customer Details",
			icon: <OpenInNew />,
			isClickable: true,
			visible: !IsLocked && !IsReadOnly,
			onClick: displayEditCustomerDetails
		},
		{
			id: "Show_PDF",
			name: documentPreviewEnabled ? "Hide PDF" : "Show PDF",
			icon: <PictureAsPdfOutlined />,
			isClickable: true,
			visible: !hidePdfViewButton && quosal.util.shouldShowPdfPreviewOption(),
			onClick: toggleDocumentPreviewer,
			testId: "toggle-pdf"
		}
	];
	const menuBtnStyles = {
		width: "100px",
		height: "32px",
		borderRadius: "6px",
		padding: "3px",
		borderStyle: "solid",
		borderColor: theme.palette.primary.main,
		fontWeight: 600,
		lineHeight: "18px",
		"& .MuiButton-endIcon>*:nth-of-type(1)": {
			fontSize: "16px"
		}
	};
	const menuItemStyles = {
		fontWeight: "400",
		lineHeight: "16px",
		fontSize: "12px",
		height: "16px",
		letterSpacing: "0.32px",
		color: theme.palette.text.secondary
	};


	const handleMenuClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	return (
		<Stack
			direction="row"
			spacing={1}
			sx={{
				paddingLeft: "0px",
			}}
		>
			<Button
				aria-controls="quote-header-menu"
				aria-haspopup="true"
				variant="text"
				onClick={handleMenuClick}
				sx={menuBtnStyles}
				endIcon={
					<ExpandMoreSharpIcon
						sx={{
							fontSize: "16px",
							borderColor: theme.palette.primary.main
						}}
					/>
				}
			>
				<Typography
					sx={{
						fontWeight: 600,
						lineHeight: "18px",
						fontSize: "14px"
					}}
				>
					Action
				</Typography>
			</Button>
			<Menu
				id="quote-header-menu"
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right"
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right"
				}}
				keepMounted
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={() => setAnchorEl(null)}
				disableScrollLock={true}
			>
				{menus.map(({ id, name, onClick, testId, visible, title, isClickable }) =>
					visible ? (
						<MenuItem
							onClick={() => {
								setAnchorEl(null);
								onClick();
							}}
							sx={!isClickable ? { cursor: "not-allowed" } : {}}
							key={id}
							data-testid={testId}
						>
							<Typography title={title} sx={menuItemStyles}>
								{name}
							</Typography>
						</MenuItem>
					) : null
				)}
			</Menu>
		</Stack>
	);
};
