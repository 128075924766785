import React from "react";

import { QuoteHeader } from "./QuoteHeader";
import { QuoteNavigationTabs } from "./QuoteNavigationTabs";
import {
	GlobalSettingsProvider,
	QuoteProvider,
	UserProvider,
	UserSettingsProvider
} from "./context";
import { useQuoteHeader } from "./customHooks";
import { QuoteHeaderTheme } from "./styles";
import { isEoPage } from "./utils";
import { DispatchProvider } from "./context/DispatchContext";
import { IdentityWarningToast } from "./common/components/IdentityWarningToast";

export const QuoteHeaderContainer = (props) => {
	const { dispatch, currentQuote, currentUser, userSettings, globalSettings } =
		useQuoteHeader(props);
	const isElectronicOrderPage = isEoPage();
	return (
		<QuoteHeaderTheme>
			<DispatchProvider dispatch={dispatch}>
				<QuoteProvider quote={currentQuote}>
					<UserProvider user={currentUser}>
						<UserSettingsProvider userSettings={userSettings}>
							<GlobalSettingsProvider globalSettings={globalSettings}>
								{props?.isLegacyPage && <IdentityWarningToast />}
								<QuoteHeader
									hidePdfViewButton={
										!!props.hidePdfViewButton || isElectronicOrderPage
									}
									hideOpportunityDetails={isElectronicOrderPage}
									isLegacyPage={props?.isLegacyPage}
								/>
							</GlobalSettingsProvider>
						</UserSettingsProvider>
					</UserProvider>
					<QuoteNavigationTabs />
				</QuoteProvider>
			</DispatchProvider>
		</QuoteHeaderTheme>
	);
};
