import {DateTimeInput} from "js/jsx/src/classes/forms/specialInputs.jsx";
import {Panel, PanelContent} from "js/jsx/src/classes/panels.jsx";

export class IngramQuoteImportSetupPrompt extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render () {
        var ingramSetupUrl = (quosal.util.url('adminrtsource.quosalweb') +
            '&rt_source_name=ingrammicrorest&quotereturnurl=' +
            encodeURIComponent(location.href));
        var ingramSetupDocumentationLink = $.quosal.help.getUniversityBaseURL() + 'S_Ingram_Micro_Mustang';
        return <div id="ingramQuoteImportSetupPrompt" style={{width: 465}}>
            <p>
                Before importing Ingram Micro<sup>&#0174;</sup> quotes into {app.productBranding}, click Account Setup to create a developer account with Ingram Micro.
                This account is different from your customer account. An email confirmation is sent to you confirming set up.
                This may take several hours.
            </p>
            <p>
                After receiving email confirmation, log into your new account and sign up for the Quote API. In your account, navigate to the Quote API to view the Application ID and Secret Key.
                Click {app.productBranding} Settings to enter the API credentials in {app.productBranding}. Click
      
                <a href={ingramSetupDocumentationLink} target="_blank" style={{ cursor: 'pointer' }}> Documentation </a>
                for details.
            </p>
            <div style={{ paddingTop: 10, paddingLeft:90}}>
                <div className="formcolumn" style={{ width: 'auto' }}>
                    <a id="ingramQuoteImportSetupDialogStep1" href="https://developer.ingrammicro.com/#/home/signup" target="_blank">
                        <Button>Account Setup</Button></a>
                </div>

                <div className="formcolumn" style={{ width: 'auto' }}>
                    <a id="ingramQuoteImportSetupDialogStep2" href={ingramSetupUrl}>
                        <Button>Sell Settings</Button></a>
                </div>
            </div>
        </div>
    }
}

export class IngramQuoteImportPage extends React.Component {
    constructor(props) {
        super(props);
        var todayDate = new Date();
        var twoWeeksAgoDate = new Date();
        twoWeeksAgoDate.setDate(twoWeeksAgoDate.getDate() - 14)
        this.state = {
            step: 'search',
            singleResultQuoteNumber: null,
            fromDate: twoWeeksAgoDate.toCompanyFormat(),
            toDate: todayDate.toCompanyFormat(),
            quoteId: ''
        };
        this.transitionToResults = this.transitionToResults.bind(this);
        this.goToSearchStart = this.goToSearchStart.bind(this);
        this.transitionToSingleQuoteResult = this.transitionToSingleQuoteResult.bind(this);
        this.importQuote = this.importQuote.bind(this);
        this.noQuoteFoundDialog = this.noQuoteFoundDialog.bind(this);
    }
    transitionToResults () {
        this.setState({
            step: 'results',
            singleResultQuoteNumber: null
        });
    }
    goToSearchStart () {
        this.setState({
            step: 'search'
        });
    }
    transitionToSingleQuoteResult() {
        this.setState({
            step: 'results',
            singleResultQuoteNumber: this.state.quoteId
        });
    }

    importQuote(params) {
        params = params || {};
        var quoteNumber = params.quoteNumber;
        var idQuoteTabs = params.idQuoteTabs;
        var suggestedItemGuids = params.suggestedItemGuids;
        var useIngramExpirationDate = params.useIngramExpirationDate;

        Dialog.setIsWorking();
        const apiCall = quosal.api.ingramQuote.importQuote(quoteNumber, this.props.idQuoteMain, idQuoteTabs, suggestedItemGuids, useIngramExpirationDate);
        apiCall.finished = function(idQuoteMain, idQuoteTabs, msg){
            if (msg.quote) {
                quosal.sell.quote.update(msg.quote);
            }
            Dialog.setIsWorking(false);
            if (msg.success === true) {
                var url = quosal.util.url('quote.dashboard', 'idquotemain=' + idQuoteMain, 'idquotetabs=' + idQuoteTabs, 'submodules=quote.content');
                quosal.navigation.navigate(url);
            } else if (msg.errorCode === 'ingramQuoteNotFound') {
                this.noQuoteFoundDialog(msg.errorMessage);
            } else if (msg.errorMessage) {
                Dialog.closeAll({skipAnimation: true});
                Dialog.open({
                    title: 'Error',
                    message: msg.errorMessage,
                    links: [Dialog.links.close]
                });
            }
        }.bind(this, this.props.idQuoteMain, idQuoteTabs);
        apiCall.call();
    }
    noQuoteFoundDialog (message) {
        Dialog.open({
            title: 'No Quote Found',
            message: <div>{message || 'We could not find a quote based on the search criteria given.'}</div>,
            links: [{
                title: 'Back to Search',
                callback: function () {
                    this.goToSearchStart();
                    Dialog.close();
                }.bind(this)
            }]
        });
    }
    render () {
        if (this.state.step == 'search') {
            return <IngramQuoteSearch fromDate={this.state.fromDate} toDate={this.state.toDate} quoteId={this.state.quoteId}
                                      setInputValue={(name, value) => {this.setState({[name]: value})}}
                                      transitionToResults={this.transitionToResults}
                                      searchButtonHandler = {this.transitionToSingleQuoteResult}
                                      {...this.props} />;
        } else if (this.state.step == 'results') {
            return <IngramQuoteSearchResults fromDate={this.state.fromDate} toDate={this.state.toDate}
                                             goToSearchStart={this.goToSearchStart}
                                             importQuoteHandler = {this.importQuote}
                                             singleResultQuoteNumber={this.state.singleResultQuoteNumber}
                                             noQuoteFoundHandler = {this.noQuoteFoundDialog}
                                             {...this.props} />;
        }
    }
}
global.IngramQuoteImportPage = IngramQuoteImportPage;

export class IngramQuoteSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.onQuoteIdChange = this.onQuoteIdChange.bind(this);
    }
    onQuoteIdChange (e) {
        this.props.setInputValue("quoteId", e.target.value);
    }
    render () {
        const dateSubmitDisabled = !(this.props.fromDate && this.props.toDate);
        const quoteIdSubmitDisabled = !(this.props.quoteId);
        return (<div>
            <Panel>
                <PanelContent>
                    <Button type="cancel" id="ingramQuoteSearchCancelButton" style={{float:'right'}}
                            onClick={() => quosal.navigation.navigate(quosal.util.url('quote.dashboard', 'submodules=quote.content'))}>
                        Cancel
                    </Button>
                </PanelContent>
            </Panel>
            <Panel id="ingramQuoteSearchByDatePanel" title="Search Your Ingram Micro Quotes by Creation Date">
                <PanelContent>
                    <div className="formcolumn">
                        <div className="formfieldlabel"><label htmlFor="ingramQuoteSearchStartDateInput" >Start Date</label></div>
                        <div className="formfield" >
                            <DateTimeInput ref="input" setValue={(value) => this.props.setInputValue("fromDate", value)} id="ingramQuoteSearchStartDateInput"
                                           name="ingramQuoteSearchStartDateInput" inputElementProps={{value:this.props.fromDate}} />
                        </div>
                    </div>
                    <div className="formcolumn">
                        <div className="formfieldlabel"><label htmlFor="ingramQuoteSearchEndDateInput" >End Date</label></div>
                        <div className="formfield" >
                            <DateTimeInput ref="input" setValue={(value) => this.props.setInputValue("toDate", value)} id="ingramQuoteSearchEndDateInput"
                                           name="ingramQuoteSearchEndDateInput" inputElementProps={{value:this.props.toDate}} />
                        </div>
                    </div>
                    <div className="formcolumn" style={{float: 'right', width: 90}}>
                        <div className="formfieldlabel"></div>
                        <div className="formfield">
                            <Button type="save" id="ingramQuoteSearchByDateSubmitButton"
                                    onClick={this.props.transitionToResults}
                                    disabled={dateSubmitDisabled}>
                                Next Step
                            </Button>
                        </div>
                    </div>
                </PanelContent>
            </Panel>
            <Panel id="ingramQuoteSearchByQuoteIdPanel" title="Import an Ingram Micro Quote by Quote ID">
                <PanelContent>
                    <div className="formcolumn">
                        <div className="formfieldlabel"><label htmlFor="ingramQuoteSearchQuoteIdInput" >Ingram Quote ID</label></div>
                        <div className="formfield" >
                            <input type="text" id="ingramQuoteSearchQuoteIdInput"
                                placeholder="Enter Quote ID"
                                value={this.props.quoteId} onChange={this.onQuoteIdChange} />
                        </div>
                    </div>
                    <div className="formcolumn" style={{float: 'right', width: 90}}>
                        <div className="formfieldlabel"></div>
                        <div className="formfield">
                            <Button type="save" id="ingramQuoteSearchByQuoteIdSubmitButton"
                                onClick={this.props.searchButtonHandler}
                                disabled={quoteIdSubmitDisabled}>
                                Search
                            </Button>
                        </div>
                    </div>
                </PanelContent>
            </Panel>
        </div>);
    }
}
global.IngramQuoteSearch = IngramQuoteSearch;

export class IngramQuoteSearchResults extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            results: null,
            previews: null,
            previewsHidden: null,
            previewIsLoading: null,
            selectedTab: this.props.defaultDestinationTab
        };
        this.doSearch = this.doSearch.bind(this);
        this.isSingleQuoteMode = this.isSingleQuoteMode.bind(this);
        this.updateStateOnTabSelectionChange = this.updateStateOnTabSelectionChange.bind(this);
        this.getPreview = this.getPreview.bind(this);
        this.togglePreview = this.togglePreview.bind(this);
        this.displayPreviewBeforeAttach = this.displayPreviewBeforeAttach.bind(this);
    }
    isSingleQuoteMode() {
        return !!this.props.singleResultQuoteNumber;
    }
    componentDidMount () {
        if (this.isSingleQuoteMode()) {
            
            var msg = {};
            this.setState({
                results: msg.searchResults || [{
                    endUserContactName: '',
                    quoteDate: '',
                    quoteNumber: this.props.singleResultQuoteNumber,
                    quoteTotal: ''
                }],
                previews: [],
                previewsHidden: [],
                previewIsLoading: []
            }, this.displayPreviewBeforeAttach.bind(this, 0));
        } else {
            this.doSearch({fromDate: this.props.fromDate, toDate: this.props.toDate});
        }
    }
    componentDidUpdate (prevProps) {
        if (
            !this.isSingleQuoteMode() &&
            (prevProps.fromDate !== this.props.fromDate ||
            prevProps.toDate !== this.props.toDate)
        ) {
            this.setState({
                results: null,
                previews: null,
                previewsHidden: null
            }, this.doSearch({fromDate: this.props.fromDate, toDate: this.props.toDate}));
        }
    }
    updateStateOnTabSelectionChange (e) {
        this.setState({
            selectedTab: e.target.value
        })
    }
    createTabRoutingDropdown () {
        var tabOptions = [];
        for (var i = 0; i < app.currentQuote.Tabs.length; i++) {
            tabOptions.push(<option key={app.currentQuote.Tabs[i].IdQuoteTabs}
                                    value={app.currentQuote.Tabs[i].IdQuoteTabs}>{app.currentQuote.Tabs[i].TabName}</option>)
        }
        return (
            <div className="formcolumn">
                <div className="formfieldwrapper">
                    <label htmlFor="tabSelection" className="formlabel">Tab Routing Options</label>
                    <div className="formselectfieldwrapper">
                        <select id="tabSelection" key="tabSelection"
                                onChange={this.updateStateOnTabSelectionChange}
                                value={this.state.selectedTab}
                                className="formselectfield">{tabOptions}</select>
                    </div>
                </div>
            </div>
        )
    }
    doSearch (args) {
        const apiCall = quosal.api.ingramQuote.searchQuotes(args.fromDate, args.toDate);
        apiCall.finished = function (msg) {
            if (this.props.fromDate === args.fromDate && this.props.toDate === args.toDate) {
                this.setState({
                    results: msg.searchResults || [],
                    previews: [],
                    previewsHidden: [],
                    previewIsLoading: []
                });
            }
        }.bind(this);
        apiCall.call();
    }
    getPreview(params) {
        var index = params.index;
        var quoteNumber = params.quoteNumber;
        var callback = params.callback;
        var suppressPreviewButtonSpinner = params.suppressPreviewButtonSpinner;

        if (!suppressPreviewButtonSpinner) {
            this.setState((prevState) => {
                var previewIsLoading = prevState.previewIsLoading.slice();
                previewIsLoading[index] = true;
                return {
                    previewIsLoading: previewIsLoading
                }
            });
        }
        var afterSetState = function (callback) {
            if ('function' === typeof callback) {
                callback();
            }
        }.bind(null, callback);
        const apiCall = quosal.api.ingramQuote.previewQuoteDetails(quoteNumber);
        apiCall.finished = function (index, msg) {
            if(msg.success==true){
            this.state.results[index].quoteDate = this.state.results[index].quoteDate || msg.quoteDetails.quoteDate;
            this.state.results[index].endUserContactName = this.state.results[index].endUserContactName || msg.quoteDetails.endUserContactName;
            }
            this.setState((prevState) => {
                var previewIsLoading1 = prevState.previewIsLoading.slice();
                previewIsLoading1[index] = false;
                var previews = this.state.previews.slice();
                if(msg.success==true){
                    previews[index] = {
                        success: true,
                        productPreviewList: msg.productPreviewList,
                        quoteDetails: msg.quoteDetails
                    };
                }
                else{
                    previews[index] = {
                        success: false,
                        errorMessage: msg.errorMessage
                    };
                }
                return {
                    previews: previews,
                    previewIsLoading : previewIsLoading1
                }
            }, afterSetState);
        }.bind(this, index);
        apiCall.call();       
    }
    displayPreviewBeforeAttach(index) {
       
        var openConfirmQuoteImportDialog = function (index) {
            Dialog.setIsWorking(false);
            var previewData = this.state.previews[index];
            if(!previewData.success){
                if(previewData.errorMessage === 'ingramQuoteNotFound' ){
                        this.props.noQuoteFoundHandler();
                }
                else{
                    Dialog.open({
                        title : 'Error',
                        message: 'Server returned an error: '+ previewData.errorMessage,
                        links: [Dialog.links.close]
                    });
                }
            } else {
                var suggestedItems = [];
                for (var i = 0; i < previewData.productPreviewList.length; i++) {
                    if (previewData.productPreviewList[i].isSuggestionProduct) {
                        suggestedItems.push(previewData.productPreviewList[i]);
                    }
                }
                var quoteDetails = previewData.quoteDetails;

                var onSuggestedSelectionUpdate = function (newSuggestedItemGuids) {
                    if (newSuggestedItemGuids && newSuggestedItemGuids.length) {
                        this.suggestedItemGuids = newSuggestedItemGuids.slice();
                    } else {
                        this.suggestedItemGuids = [];
                    }
                }.bind(this);
                Dialog.open({
                    title: 'Confirm Quote Details',
                    width: '90%',
                    message: <IngramQuoteConfirmImportMessage quoteDetails={quoteDetails}
                                                              suggestedItems={suggestedItems}
                                                              onSuggestedSelectionUpdate={onSuggestedSelectionUpdate} />,
                    links: [
                        Dialog.links.cancel,
                        {
                            title: 'Continue',
                            callback: this.checkExpirationDateAndCallImportHandler.bind(this, index, quoteDetails)
                        }
                    ]
                });
            }
        }.bind(this, index);
        if (this.state.previews[index] && this.state.previews[index].success){
            openConfirmQuoteImportDialog();
        } else {
            Dialog.setIsWorking();
            this.getPreview({
                index: index,
                quoteNumber: this.state.results[index].quoteNumber,
                callback: openConfirmQuoteImportDialog,
                suppressPreviewButtonSpinner: true
            });
        }
    }
    checkExpirationDateAndCallImportHandler(index, quoteDetails) {
        var importQuoteHandlerArgs = {
            quoteNumber: this.state.results[index].quoteNumber,
            idQuoteTabs: this.state.selectedTab,
            suggestedItemGuids: this.suggestedItemGuids
        };

        if (quoteDetails.expirationDate &&
            (quoteDetails.expirationDate !== app.currentQuote.ExpirationDate)) {

            Dialog.open({
                title: 'Update Expiration Date?',
                width: '90%',
                closeRequiresButton: true,
                message: (
                    'The current quote\'s expiration date (' +
                    app.currentQuote.ExpirationDate +
                    ') is not the same as the expiration date of the importing Ingram quote (' +
                    quoteDetails.expirationDate +
                    '). Would you like to update the current quote\'s expiration date to ' +
                    quoteDetails.expirationDate +
                    ' so that it matches the Ingram quote?'
                ),
                links: [{
                        title: 'No, Keep Existing Date',
                        callback: function (importQuoteHandlerArgs) {
                            this.props.importQuoteHandler(importQuoteHandlerArgs);
                        }.bind(this, importQuoteHandlerArgs)
                    },{
                        title: 'Yes, Update',
                        callback: function (importQuoteHandlerArgs) {
                            importQuoteHandlerArgs.useIngramExpirationDate = true;
                            this.props.importQuoteHandler(importQuoteHandlerArgs);
                        }.bind(this, importQuoteHandlerArgs)
                    }]
            });
        } else {
            this.props.importQuoteHandler(importQuoteHandlerArgs);
        }


    }
    togglePreview(index) {
        var previewsHidden = this.state.previewsHidden.slice();
        var isHiding = previewsHidden[index];
        previewsHidden[index] = !isHiding;
        this.setState({
            previewsHidden : previewsHidden
        });
    }

    render() {     
        let content = <PanelContent><div data-cy="ingramQuoteSearchSearchingMessage"><Spinner /> Searching for quotes...</div></PanelContent>;
        if (this.state.results) {
            if (this.state.results.length > 0) {
                let resultRows = [];
                let i;
                for(i = 0; i < this.state.results.length; i++) {
                    var hasPreview = (this.state.previews && this.state.previews[i]);
                    var isPreviewHidden = (this.state.previewsHidden && this.state.previewsHidden[i]);
                    var previewHandler = hasPreview ?
                        this.togglePreview.bind(this, i) :
                        this.getPreview.bind(this, { index: i, quoteNumber: this.state.results[i].quoteNumber } );
                    var attachHandler = this.displayPreviewBeforeAttach.bind(this, i);
                    resultRows.push(<IngramQuoteSearchResultsRow quote={this.state.results[i]}
                                                                 key={i}
                                                                 hasPreview={hasPreview}
                                                                 isPreviewHidden={isPreviewHidden}
                                                                 previewHandler={previewHandler}
                                                                 attachHandler={attachHandler}
                                                                 isSingleQuoteMode={this.isSingleQuoteMode()}
                                                                 previewLoading= {this.state.previewIsLoading[i]} />);
                    if (hasPreview){
                        resultRows.push(<IngramQuoteSearchResultPreviewTable key={'preview_' + i}
                                                                             isPreviewHidden={isPreviewHidden}
                                                                             data={this.state.previews[i]}
                                                                             previewIndex = {'preview_' + i} />);
                    }
                }
                content = (
                    <PanelContent>
                        {this.createTabRoutingDropdown()}
                        <table id="ingramQuoteSearchResultsGrid" className='datagrid nosort'>
                            <thead>
                                <tr>
                                    <th className="title">Quote Number</th>
                                    <th className="title">Quote Total</th>
                                    <th className="title">Quote Date</th>
                                    <th className="title">End User Contact Name</th>
                                    { !this.isSingleQuoteMode() &&
                                        <th className="nosort"></th>
                                    }
                                    <th className="nosort"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {resultRows}
                            </tbody>
                        </table>
                    </PanelContent>
                );
            }
            else {
                content = <PanelContent>No records were found for the provided date range.</PanelContent>;
            }
        }
        return (
		      <div>
                <Panel>
                    <PanelContent>
                        <Button type="cancel" id="ingramQuoteSearchCancelButton" style={{float:'right'}}
                                onClick={() => quosal.navigation.navigate(quosal.util.url('quote.dashboard', 'submodules=quote.content'))}>
                            Cancel
                        </Button>
                    </PanelContent>
                </Panel>
				<Panel id="ingramQuoteSearchResults" title="Preview Ingram Micro Quote List">
					{content}
					<div className="footer">
						<div className="left">
							<button onClick={this.props.goToSearchStart} id="previousstep" className="btn" style={{width:120}}>
								<div className="icon previous"></div>Previous Step
							</button>
						</div>
					</div>
				</Panel>
		</div>);
    }
}

global.IngramQuoteSearchResults = IngramQuoteSearchResults;

export class IngramQuoteConfirmImportMessage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selected: []
        };
        this.onCheckAllClick = this.onCheckAllClick.bind(this);
        this.renderSuggestedItemRows = this.renderSuggestedItemRows.bind(this);
        this.onSuggestedSelectionUpdate = this.onSuggestedSelectionUpdate.bind(this);
    }
    onSuggestedSelectionUpdate(selected) {
        var suggestedItemGuids = [];
        for (var i = 0; i < this.props.suggestedItems.length; i++) {
            if (selected[i]) {
                var suggestedItem = this.props.suggestedItems[i];
                suggestedItemGuids.push(suggestedItem.quoteProductGuid);
            }
        }
        this.props.onSuggestedSelectionUpdate(suggestedItemGuids);
    }
    onCheckboxClick(index, e) {
        var selected = this.state.selected.slice();
        selected[index] = e.target.checked;
        this.onSuggestedSelectionUpdate(selected);
        this.setState({selected: selected});
    }
    onCheckAllClick(e) {
        var selected;
        if (e.target.checked) {
            selected = this.state.selected.slice();
            for (var i = 0; i < this.props.suggestedItems.length; i++) {
                selected[i] = true;
            }
        } else {
            selected = [];
        }
        this.onSuggestedSelectionUpdate(selected);
        this.setState({selected: selected});
    }
    renderSuggestedItemRows(){
        var rows = this.props.suggestedItems;
        var renderedRows = [];
        for (var i = 0; i < rows.length; i++) {
            var suggestedItem = rows[i];
            renderedRows.push(<tr key={i}>
                <td className="content"><input type="checkbox"
                           checked={!!this.state.selected[i]}
                           onChange={this.onCheckboxClick.bind(this, i)} /></td>
                <td className="content">{suggestedItem.ingramPartNumber}</td>
                <td className="content">{suggestedItem.manufacturerPartNumber}</td>
                <td className="content">{suggestedItem.itemDescription}</td>
                <td className="content">{suggestedItem.msrp}</td>
                <td className="content">{suggestedItem.unitPrice}</td>
                <td className="content">{suggestedItem.quantity}</td>
                <td className="content">{suggestedItem.extendedPrice}</td>
            </tr>);
        }
        return renderedRows;
    }
    render() {
        var quoteDetails = this.props.quoteDetails;
        var areAllRowsChecked = true;
        for (var i = 0; i < this.props.suggestedItems.length; i++) {
            if (!this.state.selected[i]) {
                areAllRowsChecked = false;
                break;
            }
        }
        var hasSuggestedItems = this.props.suggestedItems && this.props.suggestedItems.length;
        var extractPlainTextFromHtml = (htmlText, quoteNumber) =>
        {
            if (htmlText) {
                var sanitizedText = htmlText.replace("<QUOTE NUMBER>", quoteNumber); 
                return (new DOMParser).parseFromString(sanitizedText, "text/html").
                    documentElement.textContent;
            }
            else {
                return "";
            }
        }
        return (
            //FSP 11/20/19 - ASG532 - These had been between Imported Vendor Message and Imported Legal Terms, before being removed pursuant to ASG532. We may add them back later; leaving here in comment for now.
            //<b>Leasing Terms:</b> {quoteDetails.leasingCalculations}<br />
            //<b>Leasing Instructions:</b> {extractPlainTextFromHtml(quoteDetails.leasingInstructions, quoteDetails.quoteNumber)}<br />
            <div>
                <p style={{ fontSize: '14px' }}>
                    <b>Quote Number:</b> {quoteDetails.quoteNumber}<br />
                    <b>Quote Expiration Date :</b> {quoteDetails.expirationDate}<br />
                    <b>Request:</b> {quoteDetails.customerNeed}<br />
                    <b>Response:</b> {quoteDetails.solutionProposed}<br />
                    <b>Imported Quote Instructions:</b> {extractPlainTextFromHtml(quoteDetails.introPreamble, quoteDetails.quoteNumber)}<br />
                    <b>Imported Purchase Instructions:</b> {quoteDetails.purchaseInstructions}<br />
                    <b>Imported Legal Terms:</b> {quoteDetails.legalTerms}<br />
                    <b>Imported Vendor Name:</b> {quoteDetails.vendorName}<br />
                    { quoteDetails.specialPricingExpirationMessages ?
                        <b><br/>{quoteDetails.specialPricingExpirationMessages.map((str, idx) =>
                            <span key={idx}>{str}<br/></span>
                        )}</b> : null
                    }
                </p>
                { !hasSuggestedItems ? '' :
                    <div>
                        <b>Products Suggested by Ingram:</b><br />
                        <table className="datagrid nosort td-quote-preview-table">
                            <thead>
                                <tr>
                                    <th><input type="checkbox"
                                               checked={areAllRowsChecked}
                                               onChange={this.onCheckAllClick} /></th>
                                    <th>Ingram Part Number</th>
                                    <th>Manufacturer Part Number</th>
                                    <th>Item Description</th>
                                    <th>MSRP</th>
                                    <th>Unit Price</th>
                                    <th>Quantity</th>
                                    <th>Extended Price</th>
                                </tr>
                            </thead>
                            <tbody>
                            {this.renderSuggestedItemRows()}
                            </tbody>
                        </table>
                    </div>
                }
            </div>
        );
    }
}

export class IngramQuoteSearchResultsRow extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {

        return <tr>
            <td className="content left">{this.props.quote.quoteNumber}</td>
            <td className="content left">{this.props.quote.quoteTotal}</td>
            <td className="content right">{this.props.quote.quoteDate}</td>
            <td className="content right">{this.props.quote.endUserContactName}</td>
            { !this.props.isSingleQuoteMode &&
                <td className="content center">
                    <Button type="preview" onClick={this.props.previewHandler} disabled={this.props.previewLoading}>
                        {this.props.previewLoading ? <Spinner/> :
                            !this.props.hasPreview ? 'Preview' :
                                this.props.isPreviewHidden ? 'Show' :
                                    'Hide'}
                    </Button>
                </td>
            }
            <td className="content center">
                <Button type="attach" onClick={this.props.attachHandler} disabled={this.props.previewLoading}>Attach</Button>
            </td>
        </tr>
    }
}

export class IngramQuoteSearchResultPreviewTable extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
        this.renderRows = this.renderRows.bind(this);
    }
    renderRows(){
        var rows = this.props.data.productPreviewList;
        var renderedRows = [];
        for (var iRow = 0; iRow < rows.length; iRow++) {
            var product = rows[iRow];
            if(!product.isSuggestionProduct) {
                renderedRows.push(<IngramQuoteSearchResultsPreviewRow productPreview = {product} key= {this.props.previewIndex + '_row_' + iRow}/>)
            }
        }
        return renderedRows;
    }
    render () {      
        var content;
        if(this.props.data.success==true)
        {
            if(this.props.data.productPreviewList && this.props.data.productPreviewList.length > 0) {
                content = <table className="datagrid nosort td-quote-preview-table">
                        <thead>
                        <tr>
                            <th>Ingram Part Number</th>
                            <th>Manufacturer Part Number</th>
                            <th>Item Description</th>
                            <th>MSRP</th>
                            <th>Unit Price</th>
                            <th>Quantity</th>
                            <th>Extended Price</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.renderRows()}
                        </tbody>
                    </table>
            }
            else{
                content = "Quote Product Records not found";
            }
        }
        else{
            content = this.props.data.errorMessage ? this.props.data.errorMessage : "Ingram API returned an Error Response";           
        }
        
        var style = {
            display: this.props.isPreviewHidden ? 'none' : 'table-row'
        };         
        return <tr style={style} id={this.props.id}>
                    <td colSpan={6} className="content center">
                    {content}
                </td>
        </tr>
    }
}

export class IngramQuoteSearchResultsPreviewRow extends React.PureComponent{

    constructor(props) {
        super(props);   
        this.state = {};    
    }
    render(){      
            return <tr>
                <td className="content left">{this.props.productPreview.ingramPartNumber}</td>
                <td className="content left">{this.props.productPreview.manufacturerPartNumber}</td>
                <td className="content right">{this.props.productPreview.itemDescription}</td>
                <td className="content right">{this.props.productPreview.msrp}</td>
                <td className="content right">{this.props.productPreview.unitPrice}</td>
                <td className="content right">{this.props.productPreview.quantity}</td>
                <td className="content right">{this.props.productPreview.extendedPrice}</td>
                </tr>
    }
}