import { Autocomplete, Skeleton, TextField, styled } from "@mui/material";
import { separatorLightGrey } from "../theme";

const StyledOption = styled("li")({
	padding: "10px !important",
	borderBottom: `1px solid ${separatorLightGrey}`
});

const withSkeleton =
	(Component) =>
	({ skeleton, ...props }) => {
		return skeleton ? (
			<Skeleton variant="rounded" width={"100%"}>
				<Component {...props} />
			</Skeleton>
		) : (
			<Component {...props} />
		);
	};

const DropdownComponent = ({
	name,
	label,
	value,
	onChange,
	options,
	disabled = false,
	disableClearable = false,
	required = false,
	sx = {},
	placeholder,
	skeleton,
	...props
}) => {
	return (
		<Autocomplete
			value={value}
			onChange={(_, newValue) => {
				onChange(newValue);
			}}
			id={name}
			options={options}
			sx={{
				"& .MuiOutlinedInput-root": {
					padding: "1px 16px"
				},
				"& .MuiAutocomplete-inputRoot": {
					"> .MuiAutocomplete-input": {
						paddingLeft: "0px"
					}
				},
				"& .MuiAutocomplete-endAdornment": {
					">button": {
						minWidth: "28px",
						minHeight: "28px"
					}
				},
				"& .Mui-focused .MuiInputBase-input": {
					boxShadow: "none"
				},
				...sx
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					fullWidth
					InputLabelProps={{
						shrink: true
					}}
					required={required}
					placeholder={placeholder}
				/>
			)}
			renderOption={
				props.renderOption
					? (props, option, state, ownerState) =>
							props.renderOption(props, option, state, ownerState)
					: undefined
			}
			disabled={disabled}
			{...props}
		/>
	);
};

const Dropdown = withSkeleton(DropdownComponent);

export { Dropdown, StyledOption };
