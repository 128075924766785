import { useState, useEffect, useCallback, useRef } from "react";

export const useResizerContainer = (onResize) => {
	const [mousePressState, setMouseDown] = useState(false);
	const MIN_WIDTH_PERCENT = 25;
	const leftResizeRef = useRef(null);

	const handleMouseDown = useCallback(() => {
		setMouseDown(true);
	}, []);

	useEffect(() => {
		const handleMouseMove = (e) => {
			if (mousePressState) {
				const ratio = window.devicePixelRatio;
				const movementX = parseFloat((e.movementX / ratio).toFixed(1));
				onResize(movementX, MIN_WIDTH_PERCENT);
			}
		};

		window.addEventListener("mousemove", handleMouseMove);

		return () => {
			window.removeEventListener("mousemove", handleMouseMove);
		};
	}, [mousePressState, onResize, MIN_WIDTH_PERCENT]);

	useEffect(() => {
		const handleMouseUp = () => {
			setMouseDown(false);
		};

		window.addEventListener("mouseup", handleMouseUp);

		return () => {
			window.removeEventListener("mouseup", handleMouseUp);
		};
	}, []);

	useEffect(() => {
		if (leftResizeRef.current) {
			leftResizeRef.current.addEventListener('mousedown', handleMouseDown);
		}

		return () => {
			if (leftResizeRef.current) {
				leftResizeRef.current.removeEventListener('mousedown', handleMouseDown);
			}
		};
	}, [handleMouseDown]);

	return {
		leftResizeRef,
		handleMouseDown
	};
};