import { CardContainer } from "../common/components/CardContainer";
import Highcharts from "highcharts";
import PieChart from "highcharts-react-official";
import { memo, useMemo } from "react";
import { getPackageLevels, getQuoteHeaderAllowedMatrics } from "../utils";
import { useGlobalSettingsContext } from "../context";

const areEqual = (prevProps, nextProps) => {
	if (
		prevProps.allTax === nextProps.allTax &&
		prevProps.baseCurrency === nextProps.baseCurrency &&
		prevProps.quoteTotal === nextProps.quoteTotal &&
		prevProps.subtotal === nextProps.subtotal &&
		prevProps.cost === nextProps.cost &&
		prevProps.discount === nextProps.discount &&
		prevProps?.theme?.palette?.mode === nextProps?.theme?.palette?.mode
	) {
		return true;
	} else {
		return false;
	}
};

export const DonutChart = memo(
	({
		subtotal,
		allTax,
		baseCurrency,
		quoteTotal,
		cost,
		discount,
		formatCurrency,
		hideProfit,
		theme
	}) => {
		const {
			packageLevel,
			EnableOneTimeTotal,
			EnableOneTimeGrossProfit,
			EnableRecurring,
			EnableRecurringGrossProfit,
			EnableTotalQuoteValue,
			EnableDownpayment
		} = useGlobalSettingsContext();
	const packageLevels = getPackageLevels();
	const isNonPremiumPartner = packageLevel !== packageLevels?.premium;
	const cardMinWidth = useMemo(() => {
		const metricsAllowed = getQuoteHeaderAllowedMatrics({
			hideProfit,
			isNonPremiumPartner,
			EnableOneTimeTotal,
			EnableOneTimeGrossProfit,
			EnableRecurring,
			EnableRecurringGrossProfit,
			EnableTotalQuoteValue,
			EnableDownpayment
		});
		const isOnlyOneMetricEnabled =
			metricsAllowed.filter((metric) => Boolean(metric)).length === 1;
		return isOnlyOneMetricEnabled && EnableOneTimeTotal ? "50%" : "380px";
	}, [
		EnableOneTimeTotal,
		EnableOneTimeGrossProfit,
		EnableRecurring,
		EnableRecurringGrossProfit,
		EnableTotalQuoteValue,
		EnableDownpayment,
		isNonPremiumPartner,
		hideProfit
	]);
		const formatedQuoteTotal = formatCurrency(quoteTotal);
		const options = useMemo(
			() => [
				{
					chart: {
						type: "pie",
						backgroundColor: theme.palette.background.paper,
						width: 350,
						height: 135,
						spacingTop: -21,
						marginLeft: -30,
						style: {
							fontFamily: "'Inter' , sans-serif"
						}
					},
					title: {
						useHTML: true,
						text: `<div style="display:flex;justify-content:space-between;align-items:center;width:250px;margin-left:3px;height:22px;flex-direction:row;color:${
							theme.palette.text.secondary
						};">
								<div style="display:flex;align-items:center;font-size:16px;font-weight:600;line-height:22px;width:fit-content;">One Time Total</div>
								<div title="${formatedQuoteTotal}" style="display:flex;align-items:center;height:18px;line-height:18px;font-size:14px;font-weight:400;width:fit-content;"> ${
									formatedQuoteTotal
						} </div>
							</div>`,
						align: "center",
						x: 44,
						y: 60
					},
					credits: {
						enabled: false
					},
					plotOptions: {
						pie: {
							allowPointSelect: false,
							cursor: "pointer",
							dataLabels: {
								enabled: false
							},
							innerSize: "80%"
						}
					},
					legend: {
						layout: "vertical",
						align: "right",
						verticalAlign: "bottom",
						itemMarginBottom: hideProfit ? 15 : 1,
						width: 200,
						symbolHeight: 12,
						useHTML: true,
						labelFormatter: function () {
							let formatted = `
								<div style="display:flex;justify-content:space-between;width:227px;flex-direction:row;color:${
									theme.palette.text.secondary
								};">
								<div title="${
									this.name === "Disc Amt"
										? "Suggested Price"
										: this.name
								}"  text-anchor="start" style="color:${
								theme.palette.text.secondary
							};font-size:14px;font-weight:400;line-height:18px;letter-spacing:0.16px;height:18px;">${
								this?.name === "Disc Amt" ? "List Price" : this?.name
							} 
								</div>
								<div title=${formatCurrency(
									this.isNagetive ? -Math.abs(this?.y) : this?.y
								)} text-anchor="start" style="color:${
								theme.palette.text.secondary
							};font-size:14px;font-weight:400;line-height:18px;letter-spacing:0.16px;height:18px;"> 
								${formatCurrency(this.isNagetive ? -Math.abs(this?.y) : this?.y)}
								</div>
								</div>
								`;
							return formatted;
						}
					},
					tooltip: {
						formatter: function () {
							return `<strong>  ${
								this?.key === "Est. Tax" ? "Estimated Tax" : this?.key
							}  </strong> <br/> <strong> ${formatCurrency(
								this?.point?.options?.isNagetive ? -Math.abs(this?.y) : this?.y
							)} </strong>`;
						}
					},
					series: [
						{
							name: "",
							color: theme.palette.text.secondary,
							lineWidth: 1,
							marker: {
								enabled: false,
								symbol: "circle",
								radius: 3,
								states: {
									hover: {
										enabled: true,
										lineWidth: 1
									}
								}
							},
							data: [
								{
									name: "Subtotal",
									y: Math.sign(subtotal) < 0 ? Math.abs(subtotal) : subtotal,
									color: theme.palette.icon.primary,
									isNagetive: Math.sign(subtotal) < 0 
								},
								{
									name: "Est. Tax",
									y: Math.sign(allTax) < 0 ? Math.abs(allTax) : allTax,
									color: theme.palette.icon.turnary,
									isNagetive: Math.sign(allTax) < 0 
								},
								{
									name: "Cost",
									y:  Math.sign(cost) < 0
											? Math.abs(cost)
											: cost,
									color: theme.palette.icon.costIconColor,
									isNagetive: Math.sign(cost) < 0
								},
								{
									name: "Disc Amt",
									y: Math.sign(discount) < 0
											? Math.abs(discount)
											: discount,
									color: theme.palette.primary.main,
									isNagetive: Math.sign(discount) < 0
								}
							],
							showInLegend: true
						}
					],
					accessibility: {
						enabled: false
					}
				},
				{
					// This section is for blank donut
					chart: {
						type: "pie",
						backgroundColor: theme.palette.background.paper,
						width: 350,
						height: 135,
						spacingTop: 16,
						style: {
							fontFamily: "'Inter' , sans-serif"
						}
					},
					title: {
						text: `<p style="font-size:16px; font-weight:700;">${formatCurrency(
							0
						)}</p><br/> <p style="font-size:12px; font-weight:400;">One Time<br/>Total</p>`,
						align: "center",
						verticalAlign: "middle",
						x: -111,
						y: 18,
						style: {
							fontSize: 12,
							fontWeight: 600,
							color: theme.palette.text.secondary,
							lineHeight: 22
						}
					},
					credits: {
						enabled: false
					},
					plotOptions: {
						pie: {
							allowPointSelect: false,
							enableMouseTracking: false,
							cursor: "pointer",
							dataLabels: {
								enabled: false
							}
						}
					},
					legend: {
						layout: "vertical",
						align: "right",
						verticalAlign: "middle",
						x: -36,
						labelFormatter: function () {
							let formatted = `<div x="20" style="color:${
								theme.palette.text.secondary
							};font-size:14px;font-weight:400;line-height:18px;letter-spacing:0.16px;height:18px;">
							${this?.name} 
							</div>
							<div x="120" style="color:${
								theme.palette.text.secondary
							};font-size:14px;font-weight:400;line-height:18px;letter-spacing:0.16px;height:18px;"> 
							${formatCurrency(0)}
							</div>`;
							return formatted;
						}
					},
					tooltip: {
						formatter: function () {
							return false;
						}
					},
					series: [
						{
							name: "",
							color: theme.palette.text.secondary,
							lineWidth: 1,
							marker: {
								enabled: false,
								symbol: "circle",
								radius: 3,
								states: {
									hover: {
										enabled: true,
										lineWidth: 1
									}
								}
							},
							data: [
								{
									name: "Subtotal",
									y: null,
									color: theme.palette.icon.primary
								},
								{
									name: "Est. Tax",
									y: null,
									color: theme.palette.icon.turnary
								},
								{
									name: "Cost",
									y: null,
									color: theme.palette.icon.costIconColor
								},
								{
									name: "Disc Amt",
									y: null,
									color: theme.palette.primary.main
								}
							],
							borderWidth: 10,
							color: theme.palette.icon.blankChart,
							showInLegend: true
						}
					],
					accessibility: {
						enabled: false
					}
				}
			],
			[subtotal, allTax, baseCurrency, quoteTotal, theme]
		);
		let selectedOptions = [...options]
		if(hideProfit){
			selectedOptions = options.map((option) => {
				option.series[0].data = option.series[0].data.filter((data) => data.name !== "Cost" && data.name !== "Disc Amt");
				return option;
			});
		}
		return (
			<CardContainer
				data-testid="test-quote-total-chart"
				sx={{ height: "128px", flex: 1, minWidth : cardMinWidth }}
				styleOutlineOverrides={{
					display: "flex",
					flex: "1",
					alignItems: "center",
					padding: "8px"
				}}
			>
				{quoteTotal === 0 ? (
					<PieChart highcharts={Highcharts} options={selectedOptions[1]} immutable={true} />
				) : (
					<>
						<PieChart highcharts={Highcharts} options={selectedOptions[0]} immutable={true} />
						<style>
							{`
								.highcharts-no-tooltip rect{
									x:-42;
								}
								.highcharts-pie-series span{
									left:-20px !important;
									width:235px !important;
								}
								.highcharts-legend-item rect{
									y:8;
								}
								
							`}
						</style>
					</>
				)}
			</CardContainer>
		);
	},
	areEqual
);
