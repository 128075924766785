/* eslint-disable no-undef */
import React, { useMemo, useCallback } from "react";
import { Box, CircularProgress, Divider, Link, Stack, Typography } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { InfoBox } from "../styledComponents/common.jsx";
import { useAppDataContext } from "../globalStates/appDataProvider";
import { StyledDataGrid } from "../styledComponents/dataGrid.jsx";
import TablePagination from "../commonComponents/tablePagination.jsx";

function PriceHistoryResults() {
	const { history, isLoadingHistory, historyGradeState, changeHistoryGradeState } =
		useAppDataContext();
	const apiRef = useGridApiRef();

	const headerHeight = 660;

	const filteredHistory = useMemo(
		() => history?.filter((item) => item.rawPrice > 0) || [],
		[history]
	);
	const minPrice = useMemo(
		() =>
			filteredHistory.length ? Math.min(...filteredHistory.map((item) => item.rawPrice)) : 0,
		[filteredHistory]
	);

	const averagePrice = useMemo(
		() =>
			filteredHistory.length
				? filteredHistory.reduce((acc, current) => acc + current.rawPrice, 0) /
				  filteredHistory.length
				: 0,
		[filteredHistory]
	);

	const maxPrice = useMemo(
		() => Math.max(...history.map((item) => item.rawPrice)) || 0,
		[history]
	);

	const columns = useMemo(
		() => [
			{
				field: "quoteId",
				headerName: "Quote",
				flex: 0.8,
				renderCell: ({ value, row }) => (
					<Link
						href={quosal.util.url(
							"quote.dashboard",
							`idquotemain=${row.idQuoteMain}`,
							"submodules=quote.content"
						)}
						target="_blank"
						sx={{ color: (theme) => `${theme.palette.secondary.main}!important` }}
					>{`#${value}v${row.version}`}</Link>
				)
			},
			{
				field: "source",
				headerName: "Source",
				flex: 1
			},
			{
				field: "cost",
				headerName: "Cost",
				flex: 0.8
			},
			{
				field: "rawPrice",
				headerName: "Price",
				flex: 0.8,
				valueFormatter: ({ value }) => app.currentQuote.formatCurrency(value)
			}
		],
		[]
	);

	const handleGridStateChange = useCallback(
		() => changeHistoryGradeState(apiRef.current.exportState()),
		[apiRef, changeHistoryGradeState]
	);

	return (
		<Box sx={{ width: "100%" }}>
			<Divider sx={{ my: 2 }} />
			{isLoadingHistory ? (
				<Stack spacing={2} justifyContent="center" direction="row" py={3}>
					<CircularProgress size={30} />
					<Typography textAlign="center">Loading...</Typography>
				</Stack>
			) : (
				<Box>
					{history?.length ? (
						<>
							<Typography
								fontSize={20}
								mb={1}
							>{`Historical: ${history?.length}`}</Typography>
							<Stack direction="row" spacing={1} mb={2}>
								<InfoBox>{`Min: ${app.currentQuote.formatCurrency(
									minPrice
								)}`}</InfoBox>
								<InfoBox>{`Avg: ${app.currentQuote.formatCurrency(
									averagePrice
								)}`}</InfoBox>
								<InfoBox>{`Max: ${app.currentQuote.formatCurrency(
									maxPrice
								)}`}</InfoBox>
							</Stack>
							<Box sx={{ height: `calc(100vh - ${headerHeight}px)` }}>
								<StyledDataGrid
									rows={history}
									columns={columns}
									apiRef={apiRef}
									onStateChange={handleGridStateChange}
									disableColumnMenu
									disableRowSelectionOnClick
									hideFooterSelectedRowCount
									pagination
									initialState={historyGradeState.initialState}
									pageSizeOptions={[5, 10, 20, 50]}
									slots={{
										pagination: TablePagination
									}}
									slotProps={{
										pagination: {
											withPriceSwitch: false
										}
									}}
									sx={{ "& .MuiTablePagination-toolbar": { padding: 0 } }}
								/>
							</Box>
						</>
					) : (
						<Typography fontSize={20} align="center">
							No price history available
						</Typography>
					)}
				</Box>
			)}
		</Box>
	);
}

export default PriceHistoryResults;
