export class DialogManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogs: []
        };
        // This binding is necessary to make `this` work in the callback
        this.open = this.open.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.close = this.close.bind(this);
        this.closeAll = this.closeAll.bind(this);
        this.setIsWorking = this.setIsWorking.bind(this);
        this.handleMaskClick = this.handleMaskClick.bind(this);
    }

    open(params) {
        var indexToReplace = -1;
        if (params.dialogId) {
            for (var i = 0; i < this.state.dialogs.length; i++) {
                if (this.state.dialogs[i].dialogId === params.dialogId) {
                    indexToReplace = i;
                }
            }
        }
        if (indexToReplace >= 0) {
            this.state.dialogs.splice(indexToReplace, 1, params);
        } else {
            this.state.dialogs.push(
                params
            );
        }
        this.setState({ dialogs: this.state.dialogs });
    }
    // params must have links or callback
    confirmDelete(params) {
        if (!params) { 
            params = {} 
        }
        //if (!params.icon) { params.icon = 'question' }
        if (!params.title) { 
            params.title = 'Confirm Delete' 
        }
        if (!params.message) { 
            params.message = 'Are you sure you want to delete?' 
        }
        if (params.closeRequiresButton === undefined) { 
            params.closeRequiresButton = true 
        }
        if (!params.links) {
            params.links = [
                { title: 'Yes, Delete', callback: params.callback },
                Dialog.links.cancel
            ]
        }
        if (!params.width) { 
            params.width = '250px' 
        }
        this.open(params);
    }
    close(params) {
        if (!params) { 
            params = {} 
        }
        if (this.state.isFading) {
            return true;    // If this function is used as a callback for a dialog button, the button will only work once unless this returns true.
        }
        var dialogToClose;
        if (params.dialogId) {
            for (var i = 0; i < this.state.dialogs.length; i++) {
                if (this.state.dialogs[i].dialogId === params.dialogId) {
                    dialogToClose = this.state.dialogs[i];
                    params.indexToDelete = i;
                }
            }
        } else {
            dialogToClose = this.refs['dialog' + (this.state.dialogs.length - 1)];
            params.indexToDelete = this.state.dialogs.length - 1;
        }
        if (!dialogToClose) { 
            return true; 
        }

        var resetState = function (params) {
            this.state.dialogs.splice(params.indexToDelete, 1);
            var stateToSet = {};
            stateToSet.dialogs = this.state.dialogs;
            stateToSet.isFading = false;
            if (this.state.dialogs.length === 0) { 
                stateToSet.isWorking = false; 
            }
            if (typeof params.callback === 'function') {
                this.setState(stateToSet, params.callback);
            } else {
                this.setState(stateToSet);
            }
        }.bind(this, params);

        if (params.skipAnimation) {
            resetState();
        } else {
            this.setState({ isFading: true });
            dialogToClose.fadeOut(resetState);
        }
        return true;
    }
    closeAll(params) {
        if (!params) { 
            params = {} 
        }
        if (this.state.isFading) {
            return true;    // If this function is used as a callback for a dialog button, the button will only work once unless this returns true.
        }

        var resetState = function () {
            var stateToSet = {};
            stateToSet.dialogs = [];
            stateToSet.isFading = false;
            stateToSet.isWorking = false;
            if (typeof params.callback === 'function') {
                this.setState(stateToSet, params.callback);
            } else {
                this.setState(stateToSet);
            }
        }.bind(this);

        if (params.skipAnimation) {
            resetState();
        } else if (this.state.dialogs.length > 0) {
            this.setState({ isFading: true });
            for (var i = this.state.dialogs.length - 1; i >= 0; i--) {
                var dialog = this.refs['dialog' + i];
                if (i === 0) {
                    dialog.fadeOut(resetState);
                } else {
                    dialog.fadeOut();
                }
            }
        }
        return true;
    }

    setIsWorking(isWorking) {
        this.setState({ isWorking: (isWorking !== false) });  // Default (if there's no argument) is true
    }

    handleMaskClick() {
        if (this.state.isWorking) {
            return false;
        }
        var dialogToClose = this.refs['dialog' + (this.state.dialogs.length - 1)];
        if (dialogToClose.props.closeRequiresButton) {
            return false;
        } else {
            (dialogToClose.props.onClose || Dialog.close).call(dialogToClose);
        }
    }

    offlineUp() { Dialog.setIsWorking(false); }
    offlineDown() { Dialog.setIsWorking(true); }
    componentDidMount() {
        DialogManager.singleton = this;
        if (window.Offline) {
            Offline.on('up', this.offlineUp);
            Offline.on('down', this.offlineDown);
        }

        if (app.settings.user.errorWhileLoading) {
            Dialog.open({
                title: app.settings.user.errorWhileLoading.title,
                message: app.settings.user.errorWhileLoading.message,
                width: '300px',
                links: [Dialog.links.close]
            });
        }
    }

    componentWillUnmount() {
        DialogManager.singleton = null;
        if (window.Offline) {
            Offline.off('up', this.offlineUp);
            Offline.off('down', this.offlineDown);
        }
    }

    render() {
        var baseZIndex = 9998,
            zIndexIncrement = 100;
        var dialogs = [];
        for (var i = 0; i < this.state.dialogs.length; i++) {
            var isWorking = this.state.isWorking && i === this.state.dialogs.length - 1;
            dialogs.push(<Dialog ref={'dialog' + i} {...this.state.dialogs[i]} key={i} zIndex={(this.state.dialogs[i].zIndex || baseZIndex) + zIndexIncrement * i} isWorking={isWorking} dialogManager={this} lAlign={this.state.dialogs[i].linkalign} />)
        }

        if (dialogs.length > 0) {
            $.quosal?.ui?.showMask(true);
        }
        else {
            $.quosal?.ui?.hideMask(true);
        }

        var zIndexModifier = this.state.isWorking ? 0 : -1;
        var maskZIndex = baseZIndex - 1 + zIndexIncrement * (this.state.dialogs.length + zIndexModifier); // Just under the top dialog if (!!state.isWorking === false), or on top of all dialogs if true
        if (this.state.isWorking) {
            dialogs.push(<SpinnerApril2018 key={'spinner'} style={{ zIndex: maskZIndex + 1 }} ></SpinnerApril2018>);
        }

        var style = (this.state.dialogs.length > 0 || this.state.isWorking) ? {} : { display: 'none' };
        style.height = '100%';
        return (
            <div style={style}>
                <div className="windowMask" style={{ display: 'block', zIndex: maskZIndex }} onClick={this.handleMaskClick}></div>
                {dialogs}
            </div>
        );
    }
}
global.DialogManager = DialogManager;

DialogManager.singleton = null;

export class Dialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = { };
        // This binding is necessary to make `this` work in the callback
        this.getSpecifiedHeight = this.getSpecifiedHeight.bind(this);
        this.fadeOut = this.fadeOut.bind(this);
        this.handleMaximizeClick = this.handleMaximizeClick.bind(this);
    }
    static ready() {
        return !!DialogManager.singleton;
    }
    static open(params) {
        if (DialogManager.singleton != null) {
            DialogManager.singleton.open(params);
        }
    }
    static close(params) { DialogManager.singleton?.close(params); }
    static closeAll(params) { DialogManager.singleton?.closeAll(params); }
    static confirmDelete(params) { DialogManager.singleton?.confirmDelete(params); }
    static setIsWorking(isWorking) { DialogManager.singleton?.setIsWorking(isWorking); }
    static isWorking() { return DialogManager.singleton?.state.isWorking; }

    componentDidMount() {
        if (this.props.resizable) {
            $(this.refs.root).resizable();
        }
        if (this.props.draggable) {
            $(this.refs.root).draggable({ handle: '.drag-handle' });
        }

        quosal.navigation.parse(this.refs.root);

        var params = {};
        params.start = function () {
            this.setState({ readyToLoadMessage: true })
        }.bind(this);
        $(this.refs.root).fadeIn(params);
        if (this.props.fastload) {
            this.setState({readyToLoadMessage: true});
        }
    }
    componentDidUpdate(prevProps, prevState) {
        var outerHeight = parseInt($(this.refs.root).outerHeight(false));
        var outerWidth = parseInt($(this.refs.root).outerWidth(false));
        if (!this.getSpecifiedHeight()) {
            var maxHeight = $(window).height() - 100;
            if (outerHeight > maxHeight) {
                this.setState({ height: maxHeight });
            }
            if ((!this.state.calculatedHeight || Math.abs(this.state.calculatedHeight - outerHeight) > 2)) {
                this.setState({ calculatedHeight: outerHeight });
            }
        }
        if (!this.props.width && (!this.state.calculatedWidth || Math.abs(this.state.calculatedWidth - outerWidth) > 2)) {
            this.setState({ calculatedWidth: outerWidth });
        }
    }
    getSpecifiedHeight() {
        return this.props.height || this.state.height;
    }
    fadeOut(callback) {
        $(this.refs.root).fadeOut({ complete: callback });
    }
    handleMaximizeClick() {
        this.setState({ maximized: !this.state.maximized });
    }
    render() {
        var onClose = function () {
            var closeFunction = this.props.onClose || Dialog.close;
            closeFunction.call(this);
        }.bind(this);

        var dragHandleButtons = [];
        if (this.props.resizable || this.props.closeButton) {
            dragHandleButtons.push(<img key="closeButton" title="Close" className="dialogbtn close" src="img/svgs/v1.0/Action_Close.svg" onClick={onClose} />);
        }
        if (this.props.resizable) {
            dragHandleButtons.push(<img key="maximizeButton" title="Maximize" className="dialogbtn maximize" src="img/svgs/v1.0/Action_Maximize.svg" onClick={this.handleMaximizeClick} />);
        }

        var icon;
        if (this.props.icon) {
            if (typeof this.props.icon === 'string') {
                icon = <div className={'validation ' + this.props.icon + ' tip dialog'}></div>;
            } else {
                icon = this.props.icon;
            }
        }
        let btnClass = 'link';
        let linkstyle = {};
        if (this.props.newUI) {
            btnClass = 'cwbtn';
            linkstyle.float = 'right';
            // linkstyle.textTransform = 'uppercase';
            // linkstyle.fontWeight= 'bold';
            // linkstyle.fontSize= '14px';
            // linkstyle.marginRight= '6px';
            // linkstyle.marginLeft= '6px';
            // linkstyle.textLlign= 'center';
        }
        if (this.props.lAlign) {
            linkstyle.float = this.props.lAlign;
        }
        var links = [];
        if (this.props.links) {
            for (var i = 0; i < this.props.links.length; i++) {
                var props = {};
                if (this.props.links[i].target) {
                    props.target = this.props.links[i].target;
                }
                if (this.props.links[i].url) {
                    props.href = this.props.links[i].url;
                }
                if (this.props.links[i].id) {
                    props.id = this.props.links[i].id;
                }
                if (this.props.links[i].callback) {
                    var onClick = function (callback) {
                        if (this.isCallbackDisabled !== true) {
                            if (true != callback.apply(this)) {
                                this.isCallbackDisabled = true;
                            }
                        }
                    }.bind(this, this.props.links[i].callback);
                    props.onClick = onClick;
                }
                links.push(<a key={i} className={btnClass} {...props}>{this.props.links[i].title}</a>);
            }
        }
        var style = { zIndex: this.props.zIndex };
        if (links.length === 0) {
            linkstyle.display = 'none';
        }

        if (this.state.maximized) {
            var edgesOffscreenByThisMuchWhenMaximized = 3;
            style.height = 'calc(100% - ' + (Dialog.verticalPaddingAndBorder - edgesOffscreenByThisMuchWhenMaximized) * 2 + 'px)';
            style.top = -edgesOffscreenByThisMuchWhenMaximized;
            style.width = 'calc(100% - ' + (Dialog.horizontalPaddingAndBorder - edgesOffscreenByThisMuchWhenMaximized) * 2 + 'px)';
            style.left = -edgesOffscreenByThisMuchWhenMaximized;
        } else {
            if (this.getSpecifiedHeight()) {
                var specifiedHeight = this.getSpecifiedHeight();
                if (specifiedHeight == 1 * specifiedHeight) {
                    specifiedHeight = specifiedHeight + 'px';
                }
                style.height = specifiedHeight;
                style.top = 'calc((50% - (' + specifiedHeight + ')/2) - ' + Dialog.verticalPaddingAndBorder + 'px)';
            } else {
                var top = this.props.top;
                if (top == null && !!this.state.calculatedHeight) {
                    top = 'calc(50% - (' + this.state.calculatedHeight + 'px)/2)';
                }
                if (top != null) {
                    style.top = top;
                }
            }
            if (this.props.width) {
                style.width = this.props.width;
                style.left = 'calc((50% - (' + this.props.width + ')/2) - ' + Dialog.horizontalPaddingAndBorder + 'px)';
            }
            var left = this.props.left;
            if (left == null && !!this.state.calculatedWidth) {
                left = 'calc(50% - (' + this.state.calculatedWidth + 'px)/2)';
            }
            if (left != null) {
                style.left = left;
            }
        }
        if (this.props.newUI) {
            style.padding = '24px';
            style.borderRadius = '2px';
        }

        // Uh-oh, it's an iframe. Readjust vertical position and possibly height to fit window. JA 7/21/2016
        if (window.self !== window.top && $(window).height() / $(window).width() > 2) {
            style.top = '200px';
            if (typeof style.height === 'string' && (style.height.indexOf('%') >= 0 || style.height.indexOf('calc(') >= 0)) {
                style.height = '500px';
                style.top = '100px';
            } else if (style.height) {
                var heightInt = parseInt(style.height);
                if (heightInt) {
                    if (heightInt > 400) {
                        style.top = '100px';
                    }
                    if (heightInt > 700) {
                        style.height = '700px';
                    }
                }
            }
        }

        var message;
        var messageClass = "message"; // always do this for now (links.length === 0) ? "messagefull" : "message";
        if (!this.state.readyToLoadMessage) {
            message = <div ref="message" className={messageClass} />
        } else if (this.props.messageIsVettedHtml === true) {
            var createMarkup = function () {
                return { __html: this.props.message };
            }.bind(this);
            message = <div ref="message" className={messageClass} dangerouslySetInnerHTML={createMarkup()} />
        } else {
            message = <div ref="message" className={messageClass} {...this.props.messageProps}>{this.props.message}</div>
        }

        var sellErrorIdLine = null;
        if (this.props.sellErrorIds && this.props.sellErrorIds.length) {
            var sellErrorIdSpans = null;
            for (var i = 0; i < this.props.sellErrorIds.length; i++) {
                if (!sellErrorIdSpans) {
                    sellErrorIdSpans = [];
                    sellErrorIdSpans.push(
                        <span key="label">SellErrorID: </span>
                    );
                } else {
                    sellErrorIdSpans.push(<span key={'sep_' + i}>, </span>);
                }
                sellErrorIdSpans.push(
                    <HighlightBox key={'id_' + i}>{
                        this.props.sellErrorIds[i]
                    }</HighlightBox>
                );
            }
            sellErrorIdLine = <span className="sellErrorId">{sellErrorIdSpans}</span>;
        };

        return (
            <div ref="root" className="dialog main" style={style}>
                {icon || null}
                <div className="drag-handle">{dragHandleButtons}</div>
                {this.props.titleId ? <div className="title" id={this.props.titleId}>{this.props.title}</div> : <div className="title">{this.props.title}</div>}
                {message}
                {sellErrorIdLine || null}
                <div className="links" style={linkstyle}>{links}</div>
            </div>
        );
    }
}
global.Dialog = Dialog;
Dialog.horizontalPaddingAndBorder = 26;
Dialog.verticalPaddingAndBorder = 26;
Dialog.links = {
    ok: { title: 'OK', callback: function () { DialogManager.singleton.close(); } },
    close: { title: 'Close', callback: function () { DialogManager.singleton.close(); } },
    cancel: { title: 'Cancel', callback: function () { DialogManager.singleton.close(); } },
    no: { title: 'No', callback: function () { DialogManager.singleton.close(); } },
};

class HighlightBox extends React.Component {
    constructor(props) {
        super(props);

        this.setRootRef = element => {
            this.root = element;
        };

        this.onClick = this.onClick.bind(this);
    }
    onClick() {
        if (this.root) {
            this.root.select();
        }
    }
    render() {
        return (<input ref={this.setRootRef} readonly={true} onClick={this.onClick} value={this.props.children} />);
    }
}