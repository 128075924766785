import { styled } from '@mui/material/styles';
import { Box, Grid, Button, IconButton, Typography } from '@mui/material';


export const SectionContainerStyled = styled(Box)(({ theme }) => ({
    width: '100%',
    height: '90%',
    overflow: 'auto'
}));

export const SectionGridStyled = styled(Grid)(({ theme }) => ({
    width: '97%',
    margin: '0 auto 16px auto',
    border: 'none',
    borderRadius: 4,
    borderBottom: '1px solid',
    borderColor: theme.palette.border.border_subtle3,
}));

export const QuestionGridStyled = styled(Grid)(({ theme }) => ({
    background: theme.palette.layer.layer1,
    padding: '16px',
    margin: '16px 0',
}));

export const AddSectionButtonStyled = styled(Button)(({ theme }) => ({
    background: theme.palette.layer.layer1,
    borderRadius: '6px',
    color: theme.palette.text.main,
    textTransform: 'none',
    padding: '8px 12px',
    height: 'fit-content',
    '&:hover': {
        backgroundColor: theme.palette.layer.layer_hover1
    },
}));

export const QuestionButtonStyled = styled(Button)(({ theme }) => ({
    borderRadius: '6px',
    textTransform: 'none',
    padding: '8px 12px',
    height: 'fit-content',
    '&:hover': {
        backgroundColor: theme.palette.layer.layer_hover1
    },
}));

export const DeleteInputButtonStyled = styled(IconButton)(({ theme }) => ({
    border: '1px solid',
    borderColor: theme.palette.button_danger.secondary,
    minWidth: 0,
    minHeight: 0,
    color: theme.palette.button_danger.secondary,
    margin: 'auto',
    width: 'fit-content',
    height: 'fit-content',
    borderRadius: '6px',
    '&:hover': {
        backgroundColor: theme.palette.button_danger.secondary,
        color: theme.palette.text.on_color,
    },
    '&:disabled': {
        borderColor: 'rgba(0, 0, 0, 0.26)',
    },
}));

export const AddIconButtonStyled = styled(IconButton)(({ theme }) => ({
    border: '1px solid',
    borderColor: theme.palette.button_primary.main,
    minWidth: 0,
    minHeight: 0,
    color: theme.palette.button_primary.main,
    margin: 'auto',
    width: 'fit-content',
    height: 'fit-content',
    borderRadius: '6px',
    '&:hover': {
        backgroundColor: theme.palette.button_primary.main,
        color: theme.palette.text.on_color,
    },
    '&:disabled': {
        borderColor: 'rgba(0, 0, 0, 0.26)',
    },
}));

export const QuestionsNumbering = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto, sans-serif',
    color: theme.palette.text.main,
    fontStyle: 'normal',
    fontWeight: 400,
    paddingTop: '22px',
}));
