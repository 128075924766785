export function quoteSummaryWidgetData() {
	return {
		quoteReadableId: { value: app.currentQuote.QuoteReadableId, title: "Quote #", sort: 1 },
		version: { value: app.currentQuote.Version, title: "Version", sort: 2 },
		deliveredDate: { value: app.currentQuote.DeliveredDate, title: "Delivery Date", sort: 3 },
		expirationDate: {
			value: app.currentQuote.ExpirationDate,
			title: "Expiration Date",
			sort: 4
		}
	};
}

export function notRecurringQuoteSummaryWidgetData() {
	const tabIds = getRightTabsSideIds();
	const ckeditorTabs = app.currentQuote.Tabs.filter(
		(el) => tabIds.includes(el.IdQuoteTabs) && !el.IsOptional
	);
	const subTotalSum = ckeditorTabs.reduce((sum, el) => sum + el.Subtotal, 0);
	const taxSum = ckeditorTabs.reduce((sum, el) => (el.IsTaxable ? sum + el.Tax : sum), 0);
	const totalSum = subTotalSum + taxSum;

	const rows = ckeditorTabs.map((item, index) => ({
		id: index,
		description: item.TabName,
		amount: item.Subtotal
	}));

	return {
		rows,
		sums: {
			subTotal: subTotalSum,
			tax: taxSum,
			total: totalSum
		}
	};
}

export function quoteOptionalSummaryData() {
	const tabIds = getRightTabsSideIds();
	const ckeditorTabs = app.currentQuote.Tabs.filter(
		(el) => tabIds.includes(el.IdQuoteTabs) && el.IsOptional
	);

	const rows = ckeditorTabs.map((item, index) => ({
		id: index,
		description: item.TabName,
		recurring: item.OptionalRecurring,
		oneTime: item.OptionalSubtotal
	}));

	const reccuringTotal = ckeditorTabs.reduce((sum, el) => sum + el.OptionalRecurring, 0);
	const oneTimeTotal = ckeditorTabs.reduce((sum, el) => sum + el.OptionalSubtotal, 0);

	return {
		rows,
		sums: {
			recurring: reccuringTotal,
			oneTime: oneTimeTotal
		}
	};
}

export function recurringQuoteSummaryData(option) {
	const tabIds = getRightTabsSideIds();
	const ckeditorTabs = app.currentQuote.Tabs.filter(
		(el) => tabIds.includes(el.IdQuoteTabs) && !el.IsOptional && el.RecurringLabel === option && el.RecurringSubtotal > 0
	);

	const rows = ckeditorTabs.map((item, index) => ({
		id: index,
		description: item.TabName,
		amount: item.RecurringSubtotal
	}));

	const subTotalSum = ckeditorTabs.reduce((sum, el) => sum + el.RecurringSubtotal, 0);
	const taxSum = ckeditorTabs.reduce((sum, el) => sum + el.RecurringTax, 0);
	const totalSum = subTotalSum + taxSum;

	return {
		rows,
		recurringOption: option,
		sums: {
			subtotal: subTotalSum,
			tax: taxSum,
			total: totalSum
		}
	};
}

export function getRecurringOptions() {
	const tabIds = getRightTabsSideIds();
	const ckeditorTabs = app.currentQuote.Tabs.filter(
		(el) =>
			tabIds.includes(el.IdQuoteTabs) &&
			!el.IsOptional &&
			el.RecurringLabel &&
			el.RecurringLabel != "None"
	);

	return ckeditorTabs
		.map((item) => item.RecurringLabel)
		.filter((value, index, self) => self.indexOf(value) === index);
}

export function salesSignatureData() {
	const today = new Date();
	return {
		signature: app.currentQuote.Owner.SignatureLink,
		name: app.currentQuote.Owner.DisplayName,
		title: app.currentQuote.Owner.JobTitle,
		date: `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, "0")}-${String(
			today.getDate()
		).padStart(2, "0")}`
	};
}

export function customerSignatureData() {
	return { name: app.currentQuote.ContactName, signatureUrl: app.currentQuote.CustomerSignatureCapture, signDate: app.currentQuote.OrderPorterSignedDateUtc };
}

export function getRightTabsSideIds() {
	const pattern = /data-id="([^"]*)"/g;
	const matches = [];
	let match;

	while ((match = pattern.exec(app.currentQuote.HTMLContentForAutoSaving)) !== null) {
		matches.push(match[1]);
	}

	return matches;
}
