import React, { useCallback, useState } from "react";
import { useQuoteContext } from "../context";
import { Box, Typography } from "@mui/material";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { useTheme } from "@mui/material/styles";
import { Divider } from "@mui/material";
import PropTypes from "prop-types";

export const Opportunity = ({ isVisible }) => {
	const theme = useTheme();
	let opportunityButtonProps = null;
	const quote = useQuoteContext();

	const [allowOpportunity, setAllowOpportunity] = useState(
		quosal.util.crmAllowsOpportunityCheck()
	);

	const showOpportunityStatus = allowOpportunity && !quote.IsTemplate;

	if (allowOpportunity) {
		opportunityButtonProps = {};
		if (quote.IdCRMOpportunity) {
			opportunityButtonProps.className = "quote-opportunity-active";
			opportunityButtonProps.howerText = quote.IdCRMOpportunity + " is Attached";
			opportunityButtonProps.title = "Opportunity Details";
		} else {
			opportunityButtonProps.className = "quote-opportunity-inactive";
			opportunityButtonProps.howerText = "No Opportunity Attached";
			opportunityButtonProps.title = "Attach Opportunity";
		}
	}

	const goToOpportunity = useCallback(
		(quoteData) => {
			if (!quoteData || !quoteData.QuoteName) {
				quoteData = app.currentQuote;
			}
			if (quoteData.IsTemplate) {
				return;
			}
			let nextModule;
			let disallowCustomerAndOppSearch = quosal.settings.getValue("disallowCustomerSearch");
			let hasCustomer = quoteData.AccountName != "";
			if (!quoteData.IdCRMOpportunity && !hasCustomer && !disallowCustomerAndOppSearch) {
				nextModule = "quote.attachOpportunity";
			} else {
				nextModule = "crm.opportunity";
			}

			if (app.currentModule.Url === "quote.dashboard") {
				app.currentModule.loadSubModule("quote.customer", {
					container: "quoteModule",
					unloadSubModules: true,
					query: "nextModule=" + nextModule
				});
			} else {
				const href = quosal.util.url(
					"quote.dashboard",
					`submodules=quote.customer` + `,${nextModule}`,
					"idquotemain=" + quoteData.IdQuoteMain
				);
				window.open(href, "_self");
			}
		},
		[quote]
	);

	return (
		<>
			{isVisible && showOpportunityStatus && (
				<Box
					sx={{
						display: "flex",
						cursor: "pointer",
						alignItems: "center",
					}}
					gap={1}
					onClick={() => goToOpportunity(quote)}
				>
					<Typography
						title={opportunityButtonProps?.howerText}
						sx={{
							fontWeight: 600,
							lineHeight: "18px",
							fontSize: "14px",
							color: theme.palette.primary.main
						}}
					>
						{opportunityButtonProps?.title}
					</Typography>
					{opportunityButtonProps?.icon}
					{allowOpportunity && quote.IdCRMOpportunity ? (
						<MonetizationOnIcon
							sx={{ fontSize: "16px", color: theme.palette.primary.main }}
						/>
					) : (
						<CurrencyExchangeIcon
							sx={{ fontSize: "16px", color: theme.palette.primary.main }}
						/>
					)}
					<Divider
						orientation="vertical"
						sx={{
							color: theme.palette.divider,
							background: theme.palette.divider,
							height: "16px"
						}}
						flexItem
					/>
				</Box>
			)}
		</>
	);
};

Opportunity.propTypes = {
	isVisible: PropTypes.bool
};
