import {CloudSourceButton} from "js/jsx/src/classes/quote/cloudSourcing.jsx";

/*global React:object quosal:object Dialog:object */
export class EtilizeSearchResults extends React.Component {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.preventRapidRepeatRequestsTimeoutId = null;
        this.preventRapidRepeatRequestsInterval = 100;
        this.totalPriced = 0;
        this.totalStocked = 0;
        this.rowPrices = 0;
        this.rowStocks = 0;
        this.state = {
            searching: false,
            searchmsg: {
                count: 0,
                error: "Use the filters on the left to begin your search"
            },
            onlyPriced: false,
            onlyStocked: false,
            sortColumn: 0,
            compareMsg: null
        };
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentWillUnmount = this.componentWillUnmount.bind(this);
        this.renderReset = this.renderReset.bind(this);
		this.formatSeedLookup = this.formatSeedLookup.bind(this);
        this.seedLookupTasks = this.seedLookupTasks.bind(this);		
        this.doNewPricingLookup = this.doNewPricingLookup.bind(this);
        this.callPricing = this.callPricing.bind(this);
        this.abortPricingLookup = this.abortPricingLookup.bind(this);
        this.changeSortColumn = this.changeSortColumn.bind(this);
        this.changeConsolidatedSortColumn = this.changeConsolidatedSortColumn.bind(this);
        this.sortTable = this.sortTable.bind(this);
        this.sortConsolidatedTable = this.sortConsolidatedTable.bind(this);
        this.renderSortArrow = this.renderSortArrow.bind(this);
        this.renderTableHeader = this.renderTableHeader.bind(this);
        this.renderConsolidatedTableHeader = this.renderConsolidatedTableHeader.bind(this);
        this.lookupPrice = this.lookupPrice.bind(this);
        this.getPriceColumn = this.getPriceColumn.bind(this);
        this.splitCatalogPrice = this.splitCatalogPrice.bind(this);
        this.lookupPriceVal = this.lookupPriceVal.bind(this);
        this.lookupConsolidatedPriceVal = this.lookupConsolidatedPriceVal.bind(this);
        this.attachWithWarehouse = this.attachWithWarehouse.bind(this);
        this.rowWasClicked = this.rowWasClicked.bind(this);
        this.checkChanged = this.checkChanged.bind(this);
        this.renderTablePricing = this.renderTablePricing.bind(this);
        this.renderTableRow = this.renderTableRow.bind(this);
        this.renderConsolidatedTablePricing = this.renderConsolidatedTablePricing.bind(this);
        this.renderConsolidatedTableRow = this.renderConsolidatedTableRow.bind(this);
        this.renderResults = this.renderResults.bind(this);
        this.renderResultCount = this.renderResultCount.bind(this);
        this.render = this.render.bind(this);
        this.doCompare = this.doCompare.bind(this);
        this.addResultItemToQuote = this.addResultItemToQuote.bind(this);
        // var sampleProps = {
        //     phase: 'reset|search',
        //     onPrev: 'fn',
        //     onNext: 'fn'
        // };
    }

    componentDidMount() {
        this.mounted = true;
    }
    componentWillUnmount() {
        this.mounted = false;
    }

    renderReset() {
        if (this.state.searchmsg && this.state.searchmsg.count <= 0 && this.state.searchmsg.error) {
            return <div id="searchinstruct">{this.state.searchmsg.error}</div>;
        }
        return <div />;
    }
	
	formatSeedLookup(vendor, productId, mfp, sku) {
		return vendor + "~" + productId + "~" + mfp + "~" + sku;
	}

    seedLookupTasks() {
        var priceSeeds = [];
        var products = this.state.searchmsg.products;
        if (!products) {
            this.setState({ priceSeeds: priceSeeds });
            return;
        }
		
        if (!this.state.searchmsg || !this.state.searchmsg.inputCatalogs || this.state.searchmsg.inputCatalogs.length <= 0) {
            let config = this.state.searchmsg.config;
            let sources = quosal.etilizeHelper.getSourceList(config);
            for (let i = 0; i < products.length; i++) {
                for (let j = 0; j < sources.length; j++) {
                    if (sources[i] === "Catalog") {                   
                        continue;
                    }
                    let sku = quosal.etilizeHelper.productSkuBySource(config, products[i].skus, sources[j]);
                    if (sku.length == 0) {
                        sku = [products[i].ManufacturerPartNumber];
                    }
                    priceSeeds.push(this.formatSeedLookup(sources[j], products[i].productid, products[i].ManufacturerPartNumber, sku));
                }
            }

            if (config.ShowCatalogSource) {
                for (let i = 0; i < products.length; i++) {
                    let prodNum = products[i].productid + "~" + products[i].ManufacturerPartNumber;
                    if (this.state.searchmsg.prices["Catalog~" + prodNum] === "...") {
                       priceSeeds.push("Catalog~" + prodNum + "~" + prodNum);
                    }
                }
            }
        } 
        this.setState({ 
            priceSeeds: priceSeeds    
        });
    }

    doNewPricingLookup() {
        if (this.props.hidden || !this.mounted) {
            return;
        }

        let domore = true;
        while (domore) {
            var infos = this.state.priceinfo;
            if (!infos) {
                infos = {};
            }
            var haveOne = false;
            var numbers = [];
            var skus = [];
            var productIds = [];
            var onesource = '';
            var seeds = this.state.priceSeeds;

            for (let i = 0; i < seeds.length; i++) {
                if (seeds[i].length > 0) {
                    haveOne = true;
                    var combo = seeds[i].split("~");
                    if (onesource.length === 0) {
                        onesource = combo[0];
                    }
                    if (onesource === combo[0]) {
                        if (combo[3] && combo[3].length > 0) {		
							//Etilize could return multiple skus per one item -> need to split them up and have the matching product Id and mfp
							var separateSkus = combo[3].split(",");				
							for (var j = 0; j < separateSkus.length; j++) {
								productIds.push(combo[1]);
								numbers.push(combo[2]);
								skus.push(separateSkus[j]);
							}
                        }

                        // key: source~productid~mfp
                        var productKey = combo[0] + "~" + combo[1] + "~" + combo[2];
                        var key = productKey.toLowerCase();
                        if (combo[0] != 'Catalog' || (combo[0] === 'Catalog' && this.state.searchmsg.prices[productKey] === "...")) {
                            infos[key] = {
                                price: "0.00",
                                qty: 0,
                                dcost: "0.00",
                                cost: 0.0,
                                backorder: "0",
                                error: "No Part Number"
                            }
                        }
                        seeds[i] = "";
                    }
                }
                if (numbers.length > 50) {
                    break;
                }
            }
            this.setState({
                priceSeeds: seeds
            });

            if (onesource.length > 0 && numbers.length > 0) {
                this.callPricing(onesource, numbers, skus, productIds);
            }
            else if (!haveOne) {
                domore = false;
            }
        }        
    }

    callPricing(thisSrc, searchMPN, searchSkus, searchProductIds) {
        var cleanSrc = quosal.etilizeHelper.toSourceKey(thisSrc);
        var pricingApi = quosal.api.pricing.etilizePricesWithSkusProductsId(searchMPN, searchSkus, cleanSrc, app.currentQuote.IdQuoteMain, searchProductIds);
        pricingApi.finished = function(msg) {
            var infos = this.state.priceinfo;
            var disposition = "Discontinued";
            if (msg.info) {
                disposition = "No Part Number";
                for (let i = 0; i < msg.info.length; i++) {
                    var p = msg.info[i];
                    // thisSrc is local. p.Source is coded
                    var key = (thisSrc + "~" + p.ProductId + "~" + p.RefID2).toLowerCase();
					//do not show promotion price in search result
					if (p.Source != "PROMO") {
                        infos[key] = {
                            price: p.CustomerPrice,
                            qty: p.AvailableQty,
                            dcost: app.currentQuote.formatCurrency(p.CustomerPrice),
                            cost: p.CustomerPrice,
                            backorder: p.OnOrderQty,
                            error: p.ErrorMsg
                        };
					}
                }
            }
            for (let i = 0; i < searchMPN.length; i++) {
                var remainder = (thisSrc + "~" + searchMPN[i]).toLowerCase();
                if (!infos[remainder]) {
                    infos[remainder] = {
                        price: "0.00",
                        qty: 0,
                        dcost: "0.00",
                        cost: 0.0,
                        backorder: "0",
                        error: disposition
                    };
                }
            }

            this.setState(
                {
                    priceinfo: infos,
                    apiCall: null
                },
                () => {
                    //t1 = performance.now();
                    //this.doNewPricingLookup();
                }
            );
        }.bind(this);

        this.setState({
            apiCall: pricingApi
        });
        if (searchMPN.length > 0) {
            //t0 = performance.now();
            pricingApi.call();
        } else {
            if (this.mounted) {
                this.setState({
                    apiCall: null
                });
            }
        }
    }

    abortPricingLookup(callback) {
        if (this.state.apiCall) {
            this.state.apiCall.abort();

            this.setState({ apiCall: null }, callback);
        }
    }

    changeSortColumn(e, index, source) {
        var parts = e.currentTarget.id.split("~");
        var off = 0;
        if (parts.length > 1) {
            off = parseInt(parts[1], 10);
        }
        var newval = 0;
        index = off;
        if (this.state.sortColumn === index) {
            newval = 0 - this.state.sortColumn;
        } else if (this.state.sortColumn === 0 - index) {
            newval = 0 - this.state.sortColumn;
        } else {
            newval = 0 - index;
        }
        this.sortTable(newval, source);
        this.setState({
            sortColumn: newval
        });
    }

    sortTable(newCol, sourcefilter) {
        var me = this;
        if (!this.state.searchmsg || !this.state.searchmsg.products) {
            return;
        }
        var newProds = this.state.searchmsg.products.slice(0);
        if (newCol === 0) {
            newProds.sort(function(a, b) {
                var x = a.Description2.toLowerCase();
                var y = b.Description2.toLowerCase();
                if (x < y) {
                    return -1;
                }
                if (x > y) {
                    return 1;
                }
                return 0;
            });
        } else if (newCol < 0) {
            newProds.sort(function(a, b) {
                var aP = me.lookupPriceVal(sourcefilter, a.productid, a.ManufacturerPartNumber, false);
                var bP = me.lookupPriceVal(sourcefilter, b.productid, b.ManufacturerPartNumber, false);
                if (aP < bP) {
                    return -1;
                }
                if (aP > bP) {
                    return 1;
                }
                return 0;
            });
        } else {
            newProds.sort(function(a, b) {
                var aP = me.lookupPriceVal(sourcefilter, a.productid, a.ManufacturerPartNumber, true);
                var bP = me.lookupPriceVal(sourcefilter, b.productid, b.ManufacturerPartNumber, true);
                if (aP > bP) {
                    return -1;
                }
                if (aP < bP) {
                    return 1;
                }
                return 0;
            });
        }
        var theMsg = this.state.searchmsg;
        theMsg.products = newProds;
    }

    renderSortArrow(index) {
        let sortIR = <img src="skins/quosal/images/icons/ascdes.gif" alt="" />;
        if (this.state.sortColumn !== 0) {
            if (index === this.state.sortColumn || index === 0 - this.state.sortColumn) {
                if (index === this.state.sortColumn) {
                    sortIR = <img src="skins/quosal/images/icons/asc.gif" alt="ascending" />;
                } else {
                    sortIR = <img src="skins/quosal/images/icons/desc.gif" alt="ascending" />;
                }
            }
        }
        return sortIR;
    }
    renderTableHeader() {
        var headers = [];
        var offset = 3;
        var me = this;
        if (this.state.searchmsg && this.state.searchmsg.inputCatalogs && this.state.searchmsg.inputCatalogs.length > 0) {
            this.state.searchmsg.inputCatalogs.forEach(function(c, index) {
                let sortIR = me.renderSortArrow(index);
                headers.push(
                    <th
                        key={"sort" + (offset + index)}
                        className="resultsheader etilizepricecol"
                        id={c + "~" + offset}
                        onClick={e => me.changeSortColumn(e, index + offset, c)}
                    >
                        {c}
                        <br />
                        {sortIR}
                    </th>
                );
            });
        } else {
            if (this.state.searchmsg && this.state.searchmsg.config) {
                var config = this.state.searchmsg.config;
                if (config.UseJenne) {
                    let sortIR = me.renderSortArrow(offset);
                    headers.push(
                        <th
                            key={"sort" + offset}
                            className="resultsheader etilizepricecol"
                            id={"Jenne~" + offset}
                            onClick={e => this.changeSortColumn(e, offset, "Jenne")}>
                            Jenne<br />
                            {sortIR}
                        </th>
                    );
                    offset++;
                }
                if (config.UseSynnex) {
                    let sortIR = me.renderSortArrow(offset);
                    headers.push(
                        <th
                            key={"sort" + offset}
                            className="resultsheader etilizepricecol"
                            id={"TD SYNNEX~" + offset}
                            onClick={e => this.changeSortColumn(e, offset, "TD SYNNEX")}>
                            TD SYNNEX<br />
                            {sortIR}
                        </th>
                    );
                    offset++;
                }
                if (config.UseIngram) {
                    let sortIR = me.renderSortArrow(offset);
                    headers.push(
                        <th
                            key={"sort" + offset}
                            className="resultsheader etilizepricecol"
                            id={"Ingram Micro~" + offset}
                            onClick={e => this.changeSortColumn(e, offset, "Ingram Micro")}>
                            Ingram Micro<br />
                            {sortIR}
                        </th>
                    );
                    offset++;
                }
                if (config.UseDNH) {
                    let sortIR = me.renderSortArrow(offset);
                    headers.push(
                        <th
                            key={"sort" + offset}
                            className="resultsheader etilizepricecol"
                            id={"D&H~" + offset}
                            onClick={e => this.changeSortColumn(e, offset, "D&H")}>
                            D&amp;H<br />
                            {sortIR}
                        </th>
                    );
                    offset++;
                }
                if (config.UseAccutech) {
                    let sortIR = me.renderSortArrow(offset);
                    headers.push(
                        <th
                            key={"sort" + offset}
                            className="resultsheader etilizepricecol"
                            id={"Accutech Data~" + offset}
                            onClick={e => this.changeSortColumn(e, offset, "Accutech Data")}>
                            Accutech Data<br />
                            {sortIR}
                        </th>
                    );
                    offset++;
                }
                if (config.UseArbitech) {
                    let sortIR = me.renderSortArrow(offset);
                    headers.push(
                        <th
                            key={"sort" + offset}
                            className="resultsheader etilizepricecol"
                            id={"Arbitech~" + offset}
                            onClick={e => this.changeSortColumn(e, offset, "Arbitech")}>
                            Arbitech<br />
                            {sortIR}
                        </th>
                    );
                    offset++;
                }
                if (config.UseTechDataUK) {
                    let sortIR = me.renderSortArrow(offset);
                    headers.push(
                        <th
                            key={"sort" + offset}
                            className="resultsheader etilizepricecol"
                            id={"Tech Data UK~" + offset}
                            onClick={e => this.changeSortColumn(e, offset, "Tech Data UK")}>
                            Tech Data UK<br />
                            {sortIR}
                        </th>
                    );
                    offset++;
                }
                if (config.UseWestcoast) {
                    let sortIR = me.renderSortArrow(offset);
                    headers.push(
                        <th
                            key={"sort" + offset}
                            className="resultsheader etilizepricecol"
                            id={"Westcoast~" + offset}
                            onClick={e => this.changeSortColumn(e, offset, "Westcoast")}>
                            WestCoast<br />
                            {sortIR}
                        </th>
                    );
                    offset++;
                }
                if (config.UseScansource) {
                    let sortIR = me.renderSortArrow(offset);
                    headers.push(
                        <th
                            key={"sort" + offset}
                            className="resultsheader etilizepricecol"
                            id={"Scansource~" + offset}
                            onClick={e => this.changeSortColumn(e, offset, "Scansource")}>
                            Scansource<br />
                            {sortIR}
                        </th>
                    );
                    offset++;
                }
                if (config.UseBluestar) {
                    let sortIR = me.renderSortArrow(offset);
                    headers.push(
                        <th
                            key={"sort" + offset}
                            className="resultsheader etilizepricecol"
                            id={"BlueStar~" + offset}
                            onClick={e => this.changeSortColumn(e, offset, "BlueStar")}>
                            BlueStar<br />
                            {sortIR}
                        </th>
                    );
                    offset++;
                }
                if (config.UseDigitek) {
                    let sortIR = me.renderSortArrow(offset);
                    headers.push(
                        <th
                            key={"sort" + offset}
                            className="resultsheader etilizepricecol"
                            id={"Digitek~" + offset}
                            onClick={e => this.changeSortColumn(e, offset, "Digitek")}>
                            Arlington<br />
                            {sortIR}
                        </th>
                    );
                    offset++;
                }
                if (config.UseArrow) {
                    let sortIR = me.renderSortArrow(offset);
                    headers.push(
                        <th
                            key={"sort" + offset}
                            className="resultsheader etilizepricecol"
                            id={"Arrow~" + offset}
                            onClick={e => this.changeSortColumn(e, offset, "Arrow")}>
                            Arrow<br />
                            {sortIR}
                        </th>
                    );
                    offset++;
                }
                if (config.UseADI) {
                    let sortIR = me.renderSortArrow(offset);
                    headers.push(
                        <th
                            key={"sort" + offset}
                            className="resultheader etilizepricecol"
                            id={"ADI~" + offset}
                            onClick={e => this.changeSortColumn(e, offset, "ADI")}>
                            ADI<br />
                            {sortIR}
                        </th>
                    );
                    offset++;
                }
                if (config.ShowCatalogSource && !config.EtilizePrefilterResults) {
                    let sortIR = me.renderSortArrow(offset);
                    headers.push(
                        <th
                            key={"sort" + offset}
                            className="resultsheader etilizepricecol"
                            id={"Catalog~" + offset}
                            onClick={e => this.changeSortColumn(e, offset, "Catalog")}>
                            Catalog<br />
                            {sortIR}
                        </th>
                    );
                    offset++;
                }
            }
        }
        let isUnavailable = this.props.selectedProducts.length < 2;
        let msg = null;
        if (this.props.selectedProducts.length > 0) {
            if (this.props.selectedProducts.length === 1) {
                msg = 'One product selected. Select up to 3 more.';
            }
            else if (this.props.selectedProducts.length > 4) {
                msg = this.props.selectedProducts.length + ' products selected. Note: Only the first four will be used.';
            }
            else if (this.props.selectedProducts.length > 1) {
                msg = this.props.selectedProducts.length + ' products selected.';
            }
        }
        else if (this.state.compareMsg) {
            msg = this.state.compareMsg ;
        }

        return (
            <tr key="tabheader">
                <th
                    colSpan="4"
                    style={{
                        borderRight: '1px solid #dbdbdc',
                        borderBottom: '1px solid #dbdbdc',
                        textAlign: 'left'
                    }}>
                    <ActiveDisableButton 
                        unavailable={isUnavailable.toString()}
                        key="search"
                        id="btn_compare"
                        onClick={this.doCompare}
                        title="Select 2, 3, or 4 products to compare"
                        disabled={this.props.searching}>Compare</ActiveDisableButton>
                    {(msg) ? <span className='comparisonstate' style={{marginLeft:'15px'}}>{msg}</span>: null}
                </th>
                {headers}
            </tr>
        );
    }

    lookupPrice(src, productId, part, columnClass, lowest) {
        var prices = this.state.searchmsg.prices;
        var key = src + "~" + productId + "~" + part;
        var k = key.toLowerCase();
        var e = this.splitCatalogPrice(prices[key]);
        if (prices && key in prices) {
            if (e.isok === "yes") {
                this.rowPrices++;
                if (e.onhand > 0) {
                    this.rowStocks++;
                }
                let newStyle = {};
                if (lowest === e.cost || lowest === prices[key]) {
                    newStyle.backgroundColor = "#B1FF9B";
                }
                return (
                    <td key={columnClass} className={"etilizeresultcol " + columnClass + " etilizepricecol"} style={newStyle}>
                        <div className="cataloglabel">
                            ({e.source})<input className="catalogextra" value={e.source} type="hidden" />
                        </div>
                        <span className="etilizeprice">{e.formatted}</span>
                        <br />
                        <span className="etilizeqty">
                            {e.onhand} / {e.backorder}
                        </span>
                    </td>
                );
            }
            let newStyle = {};
            if (lowest === prices[key]) {
                newStyle.backgroundColor = "#B1FF9B";
            }
            return (
                <td key={columnClass} className={"etilizeresultcol " + columnClass + " etilizepricecol"} style={newStyle}>
                    {prices[key]}
                </td>
            );

        }
        else if (this.state.priceinfo) {
            var info = this.state.priceinfo[k];
            if (info) {
                if (info.error) {
                    return (
                        <td key={columnClass} className={"etilizeresultcol " + columnClass + " etilizepricecol"}>
                            <span className="etilizeerror">{info.error}</span>
                        </td>
                    );
                }
                if (info.cost === 0) {
                    return (
                        <td key={columnClass} className={"etilizeresultcol " + columnClass + " etilizepricecol"}>
                            <span className="etilizeerror">Discountinued</span>
                        </td>
                    );
                }
                this.rowPrices++;
                if (info.qty > 0) {
                    this.rowStocks++;
                }
                let newStyle = {};
                if (lowest === info.cost) {
                    newStyle.backgroundColor = "#B1FF9B";
                }
                return (
                    <td key={columnClass} className={"etilizeresultcol " + columnClass + " etilizepricecol"} style={newStyle}>
                        <span className="etilizeprice">{info.dcost}</span>
                        <br />
                        <span className="etilizeqty">
                            {info.qty} / {info.backorder}
                        </span>
                    </td>
                );
            }
            //!info
            if (e.isok === "yes") {
                this.rowPrices++;
                if (e.onhand > 0) {
                    this.rowStocks++;
                }
                let newStyle = {};
                if (lowest === e.cost) {
                    newStyle.backgroundColor = "#B1FF9B";
                }
                return (
                    <td key={columnClass} className={"etilizeresultcol " + columnClass + " etilizepricecol"} style={newStyle}>
                        <span className="etilizeprice">{e.formatted}</span>
                        <br />
                        <span className="etilizeqty">
                            {e.onhand} / {e.backorder}
                        </span>
                    </td>
                );
            }
            let newStyle = {};
            if (lowest === prices[key]) {
                newStyle.backgroundColor = "#B1FF9B";
            }
            return (
                <td key={columnClass} className={"etilizeresultcol " + columnClass + " etilizepricecol"} style={newStyle}>
                    {prices[key]}
                </td>
            );
        }
        //!priceinfo
        let newStyle = {};
        if (lowest === prices[key]) {
            newStyle.backgroundColor = "#B1FF9B";
        }
        return (
            <td key={columnClass} className={"etilizeresultcol " + columnClass + " etilizepricecol"} style={newStyle}>
                {prices[key]}
            </td>
        );
    }

    splitPriceResponse(str) {
        if (str && str.length > 0) {
            var elem = [];
            if (str[0] === ";") {
                elem = str.split(";");
            }
            return {
                cost: parseFloat(elem[1]),
                onhand: parseInt(elem[2], 10),
                backorder: parseInt(elem[3], 10),
                source: elem[4],
                formatted: elem[5],
                isok: "yes"
            };
        }
    }

    splitCatalogPrice(str) {
        if (str && str.length > 0) {
            var elem = [];
            if (str[0] === ";") {
                elem = str.split(";");
            }
            if (elem.length > 5) {
                return {
                    cost: parseFloat(elem[1]),
                    onhand: parseInt(elem[2], 10),
                    backorder: parseInt(elem[3], 10),
                    source: elem[4],
                    formatted: elem[5],
                    isok: "yes"
                };
            }
        }

        return {
            cost: 0.0,
            onhand: 0,
            backorder: 0,
            source: "",
            formatted: "",
            isok: "no"
        };
    }

    lookupPriceVal(src, productId, part, isMin) {
        var prices = this.state.searchmsg.prices;
        var key = src + "~" + productId + "~" + part;
        var cost = Number.MAX_VALUE
        if (prices && key in prices) {
            var e = this.splitCatalogPrice(prices[key]);
            if (e.cost === 0) {
                if (isMin) {
                    cost = Number.MIN_VALUE;
                }
                cost = Number.MAX_VALUE;
            }
            else {
                cost = e.cost;
            }
        }
        if (this.state.priceinfo && (cost === Number.MIN_VALUE || cost === Number.MAX_VALUE)) {
            var k = key.toLowerCase();
            var info = this.state.priceinfo[k];
            if (info) {
                if (info.error) {
                    if (isMin) {
                        return Number.MIN_VALUE;
                    }
                    return Number.MAX_VALUE;
                }
                if (info.cost === 0) {
                    if (isMin) {
                        return Number.MIN_VALUE;
                    }
                    return Number.MAX_VALUE;
                }
                return info.cost;
            }
        }
        return cost;
    }

    attachWithWarehouse(item, newData, callback) {
        item.isAttaching = true;
        this.setState({
            pendingAttachments: this.state.pendingAttachments + 1
        });

        var start = new Date().getTime();

        var info = {};
        info.productid = item.productid;
        info.source = newData.source.SourceId;
        info.cost = ''+newData.cost;
        info.msrp = ''+newData.msrp;
        info.warehouse = newData.selectedWarehouse;
        info.warehousecode = newData.warehouseCode;
        info.thumbnail = "";
        info.destination = quosal.util.queryString("idquotetabs");

        var products = [];
        products.push(info);

        var attachApi = quosal.api.product.attachEtilizeProducts(app.currentQuote.IdQuoteMain, products, true);
        attachApi.finished = function(resItem, msg) {
            resItem.isAttaching = false;
            quosal.sell.quote.update(msg.quote);
            if (this.mounted) {
                this.setState({
                    pendingAttachments: this.state.pendingAttachments - 1
                });
            }

            if (callback) {
                callback(msg.item);
            }
            quosal.util.calculateAndTrackPageLoadTimeInAppInsights(start, "Etilize - Attach Product");
        }.bind(this, item);
        attachApi.call();
    }

    rowWasClicked(e, prod) {
        if (this.props.rowClicked) {
            this.props.rowClicked(e, prod, this.state.searchmsg.prices);
        }
    }

    checkChanged(e, prod) {
        if (this.props.onCompareCheck) {
            this.props.onCompareCheck(prod.productid, e.target.checked);
        }
    }

    renderTablePricing(iRow) {
        var prod = this.state.searchmsg.products[iRow];
        prod.priced = false;
        prod.stocked = false;
        var pricing = [];
        var me = this;
        this.rowPrices = 0;
        this.rowStocks = 0;
        if (this.state.searchmsg && this.state.searchmsg.inputCatalogs && this.state.searchmsg.inputCatalogs.length > 0) {
            let lowest = Number.MAX_VALUE;
            this.state.searchmsg.inputCatalogs.forEach(function(c) {
                lowest = Math.min(lowest, me.lookupPriceVal(c, prod.productid, prod.ManufacturerPartNumber, false));
            });
            this.state.searchmsg.inputCatalogs.forEach(function(c, index) {
                pricing.push(me.lookupPrice(c, prod.productid, prod.ManufacturerPartNumber, c + "pricecol" + index, lowest));
            });
        } else {
            if (this.state.searchmsg && this.state.searchmsg.config) {
                var config = this.state.searchmsg.config;
                var minUsed = function(isUsed, val, src, part, productId) {
                    if (!isUsed) return val;
                    return Math.min(val, me.lookupPriceVal(src, productId, part, false));
                };
                var lowest = minUsed(config.UseJenne, Number.MAX_VALUE, "Jenne", prod.ManufacturerPartNumber, prod.productid);
                lowest = minUsed(config.UseSynnex, lowest, "TD SYNNEX", prod.ManufacturerPartNumber, prod.productid);
                lowest = minUsed(config.UseIngram, lowest, "Ingram Micro", prod.ManufacturerPartNumber, prod.productid);
                lowest = minUsed(config.UseDNH, lowest, "D&H", prod.ManufacturerPartNumber, prod.productid);
                lowest = minUsed(config.UseAccutech, lowest, "Accutech Data", prod.ManufacturerPartNumber, prod.productid);
                lowest = minUsed(config.UseArbitech, lowest, "Arbitech", prod.ManufacturerPartNumber, prod.productid);
                lowest = minUsed(config.UseTechDataUK, lowest, "Tech Data UK", prod.ManufacturerPartNumber, prod.productid);
                lowest = minUsed(config.UseWestcoast, lowest, "Westcoast", prod.ManufacturerPartNumber, prod.productid);
                lowest = minUsed(config.UseScansource, lowest, "Scansource", prod.ManufacturerPartNumber, prod.productid);
                lowest = minUsed(config.UseBluestar, lowest, "BlueStar", prod.ManufacturerPartNumber, prod.productid);
                lowest = minUsed(config.UseDigitek, lowest, "Digitek", prod.ManufacturerPartNumber, prod.productid);
                lowest = minUsed(config.UseArrow, lowest, "Arrow", prod.ManufacturerPartNumber, prod.productid);
                lowest = minUsed(config.UseADI, lowest, "ADI", prod.ManufacturerPartNumber, prod.productid);
                lowest = minUsed(config.ShowCatalogSource, lowest, "Catalog", prod.ManufacturerPartNumber, prod.productid);

                if (config.UseJenne) {
                    pricing.push(me.lookupPrice("Jenne", prod.productid, prod.ManufacturerPartNumber, "jennepricecol", lowest));
                }
                if (config.UseSynnex) {
                    pricing.push(me.lookupPrice("TD SYNNEX", prod.productid, prod.ManufacturerPartNumber, "synnexpricecol", lowest));
                }
                if (config.UseIngram) {
                    pricing.push(me.lookupPrice("Ingram Micro", prod.productid, prod.ManufacturerPartNumber, "ingrampricecol", lowest));
                }
                if (config.UseDNH) {
                    pricing.push(me.lookupPrice("D&H", prod.productid, prod.ManufacturerPartNumber, "dnhpricecol", lowest));
                }
                if (config.UseAccutech) {
                    pricing.push(me.lookupPrice("Accutech Data", prod.productid, prod.ManufacturerPartNumber, "accutechpricecol", lowest));
                }
                if (config.UseArbitech) {
                    pricing.push(me.lookupPrice("Arbitech", prod.productid, prod.ManufacturerPartNumber, "arbitechpricecol", lowest));
                }
                if (config.UseTechDataUK) {
                    pricing.push(me.lookupPrice("Tech Data UK", prod.productid, prod.ManufacturerPartNumber, "techdataukpricecol", lowest));
                }
                if (config.UseWestcoast) {
                    pricing.push(me.lookupPrice("Westcoast", prod.productid, prod.ManufacturerPartNumber, "westcoastpricecol", lowest));
                }
                if (config.UseScansource) {
                    pricing.push(me.lookupPrice("Scansource", prod.productid, prod.ManufacturerPartNumber, "scansourcepricecol", lowest));
                }
                if (config.UseBluestar) {
                    pricing.push(me.lookupPrice("BlueStar", prod.productid, prod.ManufacturerPartNumber, "bluestarpricecol", lowest));
                }
                if (config.UseDigitek) {
                    pricing.push(me.lookupPrice("Digitek", prod.productid, prod.ManufacturerPartNumber, "digitekpricecol", lowest));
                }
                if (config.UseArrow) {
                    pricing.push(me.lookupPrice("Arrow", prod.productid, prod.ManufacturerPartNumber, "arrowpricecol", lowest));
                }
                if (config.UseADI) {
                    pricing.push(me.lookupPrice("ADI", prod.productid, prod.ManufacturerPartNumber, "adipricecol", lowest));
                }
                if (config.ShowCatalogSource) {
                    pricing.push(me.lookupPrice("Catalog", prod.productid, prod.ManufacturerPartNumber, "catalogpricecol", lowest));
                }
            }
        }

        if (this.rowPrices > 0) {
            this.totalPriced++;
            prod.priced = true;
        }
        if (this.rowStocks > 0) {
            this.totalStocked++;
            prod.stocked = true;
        }

        return pricing;
    }

    renderTableRow(iRow) {
        var prod = this.state.searchmsg.products[iRow];
        if (iRow === 1 && !this.didshowone) {
            this.didshowone = true;
        }
        var pricing = this.renderTablePricing(iRow);
        if (this.state.onlyPriced && !prod.priced) {
            this.skippedRows++;
            return null;
        }
        if (this.state.onlyStocked && !prod.stocked) {
            this.skippedRows++;
            return null;
        }

        var chk = this.props.selectedProducts.indexOf(prod.productid) >= 0;
        return (
            <tr className="etilizeresultrow" key={"row" + iRow}>
                <td className="etilizeresultcol etilizecheckcol">
                    <input type="checkbox" onClick={e => this.checkChanged(e, prod)} defaultChecked={chk} />
                </td>
                <td className="etilizeresultcol etilizeimgcol">
                    <CloudSourceButton item={prod} className="contentgrid" type="etilizesearch" buttonText="Attach" customUpdate={this.attachWithWarehouse} />
                </td>
                <td className="etilizeresultcol etilizeimgcol">
                    <img src={prod.imageUrl} className="etilizeimgsmall" alt="thumbnail" />
                </td>
                <td className="etilizeresultcol etilizedesccol" onClick={e => this.rowWasClicked(e, prod)}>
                    <div>
                        <span className="etilizepartnum">{prod.ManufacturerPartNumber}</span>
                        <span className="etilizedesc1">{prod.Description2}</span>
                    </div>
                    <div className="etilizedesc2">{prod.Description3}</div>
                </td>
                {pricing}
            </tr>
        );
    }

    renderTableContent() {
        var rows = [];

        if (this.props.productsWithPricing && this.props.productsWithPricing.length > 0) {
            rows.push(this.renderConsolidatedTableHeader());
            for (var i = 0; i < this.props.productsWithPricing.length; i++) {
                rows.push(this.renderConsolidatedTableRow(i));
            }
        }
        else if (this.state.searchmsg.products) {
            rows.push(this.renderTableHeader());
            for (var i = 0; i < this.state.searchmsg.products.length; i++) {
                rows.push(this.renderTableRow(i));
            }
        }
        if (this.skippedRows > 0) {
            var reason = "without pricing";
            if (this.state.onlyStocked) {
                reason = "not in stock";
            }
            rows.push(
                <tr id="hiddenResults" key="tabhiddeninfo">
                    <td colSpan="4">
                        <div>
                            Hiding <span id="hiddenCount">{this.skippedRows}</span> item(s) <span id="hiddenReason">{reason}</span>
                        </div>
                    </td>
                </tr>
            );
        }
        return rows;
    }

    renderResults() {
        var oldPriced = this.totalPriced;
        var oldStock = this.totalStocked;
        this.totalPriced = 0;
        this.totalStocked = 0;
        if (!this.state.searchmsg || this.state.searchmsg.count === 0) {
            return <div />;
        }

        var block = (
            <div id="etilizeresults">
                <table id="resultslist" width="100%" cellSpacing={0} cellPadding={0}>
                    <tbody>{this.renderTableContent()}</tbody>
                </table>

                <div id="etilizelink" width="166">
                    <a href="http://www.etilize.com?utm_source=client&utm_medium=banner&utm_campaign=logoLink" rel="noopener noreferrer" target="_blank">
                        <img src="https://content.etilize.com/logoprogram/gfk-powered-by-logo-small.png" width="166" height="22" alt="product information" />
                    </a>
                </div>
            </div>
        );
        if (oldPriced !== this.totalPriced || oldStock !== this.totalStocked) {
            this.props.pricingCompleted(this.totalPriced, this.totalStocked);
        }
        return block;
    }

    renderResultCount() {
        if (!this.state.searchmsg || this.state.searchmsg.count <= 0) {
            return <div />;
        }

        var m = this.state.searchmsg;
        var status = "";
        var prevPage = <span />;
        var nextPage = <span />;
        if (m.productsScanned > 0) {
            var maxPage = m.totalPages;
            var currentPage = m.currentPage - 1
            status = "We scanned pages 1 - " + currentPage + " out of " + m.totalPages + " and gathered " + this.props.productsWithPricing.length + " products with pricing and stock";
            if ((currentPage) < maxPage) {
                nextPage = (
                    <span>
                        {" "}
                        <span id="nextpage" onClick={this.props.onMore}>
                            More...
                        </span>
                    </span>
                );
            }
        }
        else {
            var maxPage = Math.floor(m.count % m.pagesize > 0 ? m.count / m.pagesize + 1 : m.count / m.pagesize);
            var lower = (m.page - 1) * m.pagesize + 1;
            var upper = m.page * m.pagesize > m.count ? m.count : m.page * m.pagesize;

            status = lower + "-" + upper + " of " + m.count;

            if (m.page > 1) {
                prevPage = (
                    <span>
                        <span id="prevpage" onClick={this.props.onPrev}>
                            Previous Page
                        </span>{" "}
                    </span>
                );
            }

            if (m.page < maxPage) {
                nextPage = (
                    <span>
                        {" "}
                        <span id="nextpage" onClick={this.props.onNext}>
                            Next Page
                        </span>
                    </span>
                );
            }
        }

        return (
            <span id="paging">
                {prevPage}
                <span id="resultcount">{status}</span>
                {nextPage}
            </span>
        );
    }

    //The below methods are used with the experimental Pre-Filter Etilize Results By Pricing / Availability feature

    renderConsolidatedTableHeader() {
        var headers = [];
        var offset = 3;
        var me = this;

        if (this.props.productsWithPricing) {
            var config = this.props.configs;
            if (config.UseJenne) {
                let sortIR = me.renderSortArrow(offset);
                headers.push(
                    <th
                        key={"sort" + offset}
                        className="resultsheader etilizepricecol"
                        id={"Jenne~" + offset}
                        onClick={e => this.changeConsolidatedSortColumn(e, offset, "Jenne")}>
                        Jenne<br />
                        {sortIR}
                    </th>
                );
                offset++;
            }
            if (config.UseSynnex) {
                let sortIR = me.renderSortArrow(offset);
                headers.push(
                    <th
                        key={"sort" + offset}
                        className="resultsheader etilizepricecol"
                        id={"TD SYNNEX~" + offset}
                        onClick={e => this.changeConsolidatedSortColumn(e, offset, "TD SYNNEX")}>
                        TD SYNNEX<br />
                        {sortIR}
                    </th>
                );
                offset++;
            }
            if (config.UseIngram) {
                let sortIR = me.renderSortArrow(offset);
                headers.push(
                    <th
                        key={"sort" + offset}
                        className="resultsheader etilizepricecol"
                        id={"Ingram Micro~" + offset}
                        onClick={e => this.changeConsolidatedSortColumn(e, offset, "Ingram Micro")}>
                        Ingram Micro<br />
                        {sortIR}
                    </th>
                );
                offset++;
            }
            if (config.UseDNH) {
                let sortIR = me.renderSortArrow(offset);
                headers.push(
                    <th
                        key={"sort" + offset}
                        className="resultsheader etilizepricecol"
                        id={"D&H~" + offset}
                        onClick={e => this.changeConsolidatedSortColumn(e, offset, "D&H")}>
                        D&amp;H<br />
                        {sortIR}
                    </th>
                );
                offset++;
            }
            if (config.UseAccutech) {
                let sortIR = me.renderSortArrow(offset);
                headers.push(
                    <th
                        key={"sort" + offset}
                        className="resultsheader etilizepricecol"
                        id={"Accutech Data~" + offset}
                        onClick={e => this.changeConsolidatedSortColumn(e, offset, "Accutech Data")}>
                        Accutech Data<br />
                        {sortIR}
                    </th>
                );
                offset++;
            }
            if (config.UseArbitech) {
                let sortIR = me.renderSortArrow(offset);
                headers.push(
                    <th
                        key={"sort" + offset}
                        className="resultsheader etilizepricecol"
                        id={"Arbitech~" + offset}
                        onClick={e => this.changeConsolidatedSortColumn(e, offset, "Arbitech")}>
                        Arbitech<br />
                        {sortIR}
                    </th>
                );
                offset++;
            }
            if (config.UseTechDataUK) {
                let sortIR = me.renderSortArrow(offset);
                headers.push(
                    <th
                        key={"sort" + offset}
                        className="resultsheader etilizepricecol"
                        id={"Tech Data UK~" + offset}
                        onClick={e => this.changeConsolidatedSortColumn(e, offset, "Tech Data UK")}>
                        Tech Data UK<br />
                        {sortIR}
                    </th>
                );
                offset++;
            }
            if (config.UseWestcoast) {
                let sortIR = me.renderSortArrow(offset);
                headers.push(
                    <th
                        key={"sort" + offset}
                        className="resultsheader etilizepricecol"
                        id={"Westcoast~" + offset}
                        onClick={e => this.changeConsolidatedSortColumn(e, offset, "Westcoast")}>
                        WestCoast<br />
                        {sortIR}
                    </th>
                );
                offset++;
            }
            if (config.UseScansource) {
                let sortIR = me.renderSortArrow(offset);
                headers.push(
                    <th
                        key={"sort" + offset}
                        className="resultsheader etilizepricecol"
                        id={"Scansource~" + offset}
                        onClick={e => this.changeConsolidatedSortColumn(e, offset, "Scansource")}>
                        Scansource<br />
                        {sortIR}
                    </th>
                );
                offset++;
            }
            if (config.UseBluestar) {
                let sortIR = me.renderSortArrow(offset);
                headers.push(
                    <th
                        key={"sort" + offset}
                        className="resultsheader etilizepricecol"
                        id={"BlueStar~" + offset}
                        onClick={e => this.changeConsolidatedSortColumn(e, offset, "BlueStar")}>
                        BlueStar<br />
                        {sortIR}
                    </th>
                );
                offset++;
            }
            if (config.UseDigitek) {
                let sortIR = me.renderSortArrow(offset);
                headers.push(
                    <th
                        key={"sort" + offset}
                        className="resultsheader etilizepricecol"
                        id={"Digitek~" + offset}
                        onClick={e => this.changeConsolidatedSortColumn(e, offset, "Digitek")}>
                        Arlington<br />
                        {sortIR}
                    </th>
                );
                offset++;
            }
            if (config.UseArrow) {
                let sortIR = me.renderSortArrow(offset);
                headers.push(
                    <th
                        key={"sort" + offset}
                        className="resultsheader etilizepricecol"
                        id={"Arrow~" + offset}
                        onClick={e => this.changeSortColumn(e, offset, "Arrow")}>
                        Arrow<br />
                        {sortIR}
                    </th>
                );
                offset++;
            }
            if (config.UseADI) {
                let sortIR = me.renderSortArrow(offset);
                headers.push(
                    <th
                        key={"sort" + offset}
                        className="resultsheader etilizepricecol"
                        id={"ADI~" + offset}
                        onClick={e => this.changeSortColumn(e, offset, "ADI")}>
                        ADI<br />
                        {sortIR}
                    </th>
                );
                offset++;
            }
        }
        let isUnavailable = this.props.selectedProducts.length < 2;
        let msg = null;
        if (this.props.selectedProducts.length > 0) {
            if (this.props.selectedProducts.length === 1) {
                msg = 'One product selected. Select up to 3 more.';
            }
            else if (this.props.selectedProducts.length > 4) {
                msg = this.props.selectedProducts.length + ' products selected. Note: Only the first four will be used.';
            }
            else if (this.props.selectedProducts.length > 1) {
                msg = this.props.selectedProducts.length + ' products selected.';
            }
        }
        else if (this.state.compareMsg) {
            msg = this.state.compareMsg;
        }

        return (
            <tr key="tabheader">
                <th
                    colSpan="4"
                    style={{
                        borderRight: '1px solid #dbdbdc',
                        borderBottom: '1px solid #dbdbdc',
                        textAlign: 'left'
                    }}>
                    <ActiveDisableButton
                        unavailable={isUnavailable.toString()}
                        key="search"
                        id="btn_compare"
                        onClick={this.doCompare}
                        title="Select 2, 3, or 4 products to compare"
                        disabled={this.props.searching}>Compare</ActiveDisableButton>
                    {(msg) ? <span className='comparisonstate' style={{ marginLeft: '15px' }}>{msg}</span> : null}
                </th>
                {headers}
            </tr>
        );
    }

    renderConsolidatedTablePricing(prod) {
        var pricing = [];
        var me = this;

        if (prod) {
            prod.priceSourceMap = new Map();
            for (let i = 0; i < prod.Prices.length; i++) {
                prod.priceSourceMap.set(prod.Prices[i].vendor, prod.Prices[i])
            }

            var config = this.props.configs;
            if (config.UseJenne) {
                pricing.push(me.getPriceColumn(prod, "Jenne", "jennepricecol"));
            }
            if (config.UseSynnex) {
                pricing.push(me.getPriceColumn(prod, "TD SYNNEX", "synnexpricecol"));
            }
            if (config.UseIngram) {
                pricing.push(me.getPriceColumn(prod, "Ingram Micro", "ingrampricecol"));
            }
            if (config.UseDNH) {
                pricing.push(me.getPriceColumn(prod, "D&H", "dnhpricecol"));
            }
            if (config.UseAccutech) {
                pricing.push(me.getPriceColumn(prod, "Accutech Data", "accutechpricecol"));
            }
            if (config.UseArbitech) {
                pricing.push(me.getPriceColumn(prod, "Arbitech", "arbitechpricecol"));
            }
            if (config.UseTechDataUK) {
                pricing.push(me.getPriceColumn(prod, "Tech Data UK", "techdataukpricecol"));
            }
            if (config.UseWestcoast) {
                pricing.push(me.getPriceColumn(prod, "Westcoast", "westcoastpricecol"));
            }
            if (config.UseScansource) {
                pricing.push(me.getPriceColumn(prod, "Scansource", "scansourcepricecol"));
            }
            if (config.UseBluestar) {
                pricing.push(me.getPriceColumn(prod, "BlueStar", "bluestarpricecol"));
            }
            if (config.UseDigitek) {
                pricing.push(me.getPriceColumn(prod, "Digitek", "digitekpricecol"));
            }
            if (config.UseArrow) {
                pricing.push(me.getPriceColumn(prod, "Arrow", "arrowpricecol"));
            }
            if (config.UseADI) {
                pricing.push(me.getPriceColumn(prod, "ADI", "adipricecol"));
            }
        }
        return pricing;
    }

    renderConsolidatedTableRow(iRow) {
        var prod = this.props.productsWithPricing[iRow];
        if (prod) {
            if (iRow === 1 && !this.didshowone) {
                this.didshowone = true;
            }
            var pricing = this.renderConsolidatedTablePricing(prod);

            var chk = this.props.selectedProducts.indexOf(prod.productid) >= 0;
            return (
                <tr className="etilizeresultrow" key={"row" + iRow}>
                    <td className="etilizeresultcol etilizecheckcol">
                        <input type="checkbox" onClick={e => this.checkChanged(e, prod)} defaultChecked={chk} />
                    </td>
                    <td className="etilizeresultcol etilizeimgcol">
                        <CloudSourceButton item={prod} className="contentgrid" type="etilizesearch" buttonText="Attach" customUpdate={this.attachWithWarehouse} />
                    </td>
                    <td className="etilizeresultcol etilizeimgcol">
                        <img src={prod.imageUrl} className="etilizeimgsmall" alt="thumbnail" />
                    </td>
                    <td className="etilizeresultcol etilizedesccol" onClick={e => this.rowWasClicked(e, prod)}>
                        <div>
                            <span className="etilizepartnum">{prod.ManufacturerPartNumber}</span>
                            <span className="etilizedesc1">{prod.Description2}</span>
                        </div>
                        <div className="etilizedesc2">{prod.Description3}</div>
                    </td>
                    {pricing}
                </tr>
            );
        }
    }

    getPriceColumn(product, vendor, columnClass) {
        let newStyle = {};
        let productprice = product.priceSourceMap.get(vendor)
        if (productprice !== undefined) {
            if (productprice.price === product.BestPrice) {
                newStyle.backgroundColor = "#B1FF9B";
            }
            return (
                <td key={columnClass} className={"etilizeresultcol " + columnClass + " etilizepricecol withpricing"} style={newStyle} onClick={() => this.addResultItemToQuote(product, vendor)}>
                    <div className="cataloglabel">
                        ({vendor})<input className="catalogextra" value={vendor} type="hidden" />
                    </div>
                    <span className="etilizeprice">{productprice.formattedPrice}</span>
                    <br />
                    <span className="etilizeqty">
                        {productprice.onHand} / {productprice.onOrder}
                    </span>
                </td>
            );
        }
        else {
            return (
                <td key={columnClass} className={"etilizeresultcol " + columnClass + " etilizepricecol"} style={newStyle}>
                </td>
            );
        }
    }
    addResultItemToQuote(product, source) {
        let products = [];
        let mainItem = {
            productid: product.productid,
            source: product.priceSourceMap.get(source).vendorFriendlyName,
            vendorPartNumber: product.priceSourceMap.get(source).vendorSku,
            manufacturerPartNumber: product.ManufacturerPartNumber,
            thumbnail: product.imageUrl,
            cost: product.priceSourceMap.get(source).price,
            description1: product.Description1,
            description2: product.Description2,
            description3: product.Description3,
            msrp: product.MSRP,
            manufacturerName: product.ManufacturerName,
            category: product.Category,
            isCompleteItem: true,
        };

        products.push(mainItem);

        Dialog.open({
            title: 'Add to Quote',
            message: (<span><Spinner style={{ marginTop: 5 }} />Please wait while product is added to the quote</span>),
            closeRequiresButton: true,
            links: []
        });

        var attachApi = quosal.api.product.attachEtilizeProducts(
            app.currentQuote.IdQuoteMain,
            products,
            false
        );
        attachApi.finished = function (amain, msg) {
            amain.isAttaching = false;
            quosal.sell.quote.update(msg.quote);
            Dialog.closeAll();
        }.bind(this, mainItem);
        attachApi.call();
    }

    changeConsolidatedSortColumn(e, index, source) {
        var parts = e.currentTarget.id.split("~");
        var off = 0;
        if (parts.length > 1) {
            off = parseInt(parts[1], 10);
        }
        var newval = 0;
        index = off;
        if (this.state.sortColumn === index) {
            newval = 0 - this.state.sortColumn;
        } else if (this.state.sortColumn === 0 - index) {
            newval = 0 - this.state.sortColumn;
        } else {
            newval = 0 - index;
        }
        this.sortConsolidatedTable(newval, source);
        this.setState({
            sortColumn: newval
        });
    }

    sortConsolidatedTable(newCol, sourcefilter) {
        var me = this;
        if (!this.props.productsWithPricing) {
            return;
        }
        var newProds = this.props.productsWithPricing.slice(0);
        if (newCol === 0) {
            newProds.sort(function (a, b) {
                var x = a.Description2.toLowerCase();
                var y = b.Description2.toLowerCase();
                if (x < y) {
                    return -1;
                }
                if (x > y) {
                    return 1;
                }
                return 0;
            });
        } else if (newCol < 0) {
            newProds.sort(function (a, b) {
                var aP = me.lookupConsolidatedPriceVal(sourcefilter, a.productid, a.ManufacturerPartNumber, false);
                var bP = me.lookupConsolidatedPriceVal(sourcefilter, b.productid, b.ManufacturerPartNumber, false);
                if (aP < bP) {
                    return -1;
                }
                if (aP > bP) {
                    return 1;
                }
                return 0;
            });
        } else {
            newProds.sort(function (a, b) {
                var aP = me.lookupConsolidatedPriceVal(sourcefilter, a.productid, a.ManufacturerPartNumber, true);
                var bP = me.lookupConsolidatedPriceVal(sourcefilter, b.productid, b.ManufacturerPartNumber, true);
                if (aP > bP) {
                    return -1;
                }
                if (aP < bP) {
                    return 1;
                }
                return 0;
            });
        }
        this.props.setProducts(newProds);
    }

    lookupConsolidatedPriceVal(src, productId, part, isMin) {
        var prices = this.props.productsWithPricing;
        var result = prices.filter(obj => { return obj.productid === productId });
        var price = result[0].Prices.filter(obj => { return obj.vendor === src });
        if (price[0]) {
            return price[0].price;
        }
        else {
            if (isMin) {
                return Number.MIN_VALUE;
            }
            else {
                return Number.MAX_VALUE;
            }
        }
    }

    render() {
        this.skippedRows = 0;
        if (this.state.searching) {
            var spinnerArgs = {
                lines: 12,
                length: 12,
                width: 6,
                radius: 20
            };
            return (
                <div className="grid col4x3">
                    <div className="panel">
                        <div className="title">
                            <span>Products</span>
                        </div>
                        <div className="content">
                            {this.renderReset()}
                            <div style={{ heigth: 50 }} />
                            <div
                                style={{
                                    width: 150,
                                    marginLeft: "auto",
                                    marginRight: "auto"
                                }}>
                                <Spinner args={spinnerArgs} title="Searching..." />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else if (this.state.addOnSearching) {
            var spinnerArgs = {
                lines: 12,
                length: 12,
                width: 6,
                radius: 20
            };
            return (
                <div className="grid col4x3">
                    <div className="panel">
                        <div className="title">
                            {this.renderResultCount()}
                            <span>Products</span>
                        </div>
                        <div className="content">
                            {this.renderReset()}
                            <div
                                style={{
                                    width: 150,
                                    marginLeft: "auto",
                                    marginRight: "auto"
                                }}>
                                <Spinner args={spinnerArgs} title="Searching..." />
                            </div>
                            {this.renderResults()}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="grid col4x3">
                <div className="panel">
                    <div className="title">
                        {this.renderResultCount()}
                        <span>Products</span>
                    </div>
                    <div className="content">
                        {this.renderReset()}
                        {this.renderResults()}
                    </div>
                </div>
            </div>
        );
    }

    doCompare() {
        if (this.props.selectedProducts.length === 0) {
            this.setState({
                compareMsg: 'You must select 2, 3, or 4 products to compare'
            });
            return;
        }
        if (this.props.selectedProducts.length < 2) {
            this.setState({
                compareMsg: 'You must select 2, 3, or 4 products to compare'
            });
            Dialog.open({
                title: "Product Comparison",
                message: "You must select 2, 3, or 4 products to compare",
                closeRequiresButton: true,
                linkalign: "right",
                links: [{ title: "OK", callback: Dialog.close }]
            });
            return;
        }
        this.setState({
            compareMsg: null
        });
        if (this.props.onCompare) {
            this.props.onCompare();
        }
    }
}
global.EtilizeSearchResults = EtilizeSearchResults;

export class ActiveDisableButton extends React.Component {
    render() {
        let btnStyle = {} || this.props.style;
        if (this.props.unavailable === true || this.props.unavailable === 'true') {
            btnStyle.color = 'graytext';
        }
        return <button {...this.props} style={btnStyle}>{this.props.loadingpricesources === 'true' ? <Spinner></Spinner> : this.props.children}</button>;
    }
}
