import { useEffect, useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Stack, Grid,  Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { OtherUsersWarningToast } from "./common/components/OtherUsersWarningToast";
import { StyledCard } from "./common/styledComponents";
import { StyledTypographyBold } from "./common/styledComponents/StyledTypographyBold";
import {
	CustomerDetails,
	DonutChart,
	EditCustomerDetails,
	QuoteDates,
	QuoteHeaderMenu,
	QuoteNameContainer,
	QuoteStatistics,
	OneTimeTotal,
	QuoteHeaderCustomization
} from "./components";
import { useGlobalSettingsContext, useQuoteContext, useUserContext, useUserSettingsContext } from "./context";
import { formatOtherUsersList } from "./utils";
import { Opportunity } from "./components/Opportunity";

const collapsed_dict = {
	collapsed: true,
	expanded: false
};


export const QuoteHeader = ({ hidePdfViewButton = false, hideOpportunityDetails = false, isLegacyPage = false }) => {
	var cookie = quosal.util.cookie("panel_quoteheader");
	const [showEditCustDetails, setShowEditCustDetails] = useState(false);
	const [isCollapsed, setIsCollapsed] = useState(collapsed_dict?.[cookie]);
	const quote = useQuoteContext();
	const { hideProfit, isAdmin } = useUserSettingsContext();
	const {EnableOneTimeTotal} = useGlobalSettingsContext();
 const [showMultlipleUserQuoteWarning, setShowMultlipleUserQuoteWarning] = useState(false);
	// following state is for other users list accessing the same quote
	const [otherUsersList, setOtherUsersList] = useState("");
	const { IsReadOnly } = useUserContext();
	const { OtherUsers } = useQuoteContext();

	useEffect(() => {
		if (OtherUsers && OtherUsers.length > 0) {
			setShowMultlipleUserQuoteWarning(true);
		}
		setOtherUsersList(formatOtherUsersList(OtherUsers));
	}, []);

	const displayEditCustomerDetails = () => {
		setShowEditCustDetails(true);
	};

	const hideEditCustomerDetails = (isFormValid) => {
		if (isFormValid) {
			setShowEditCustDetails(false);
		}
	};
	const saveCookie = (val) => {
		quosal.util.cookie("panel_quoteheader", val ? "collapsed" : "expanded", {
			expires: 365,
			path: "/"
		});
	};
	const toggleCollapse = () => {
		setIsCollapsed((prevState) => {
			saveCookie(!prevState);
			return !prevState;
		});
	};
	const theme = useTheme();

	const getCurrency = function (params) {
		return quote.formatCurrency(params);
	};

	return (
		<>
			<OtherUsersWarningToast
				isMultipleUser={showMultlipleUserQuoteWarning}
				otherUsers={OtherUsers}
				otherUsersList={otherUsersList}
			/>
			<StyledCard
				data-testid={isCollapsed ? "collapsed_view" : "expanded_view"}
				sx={{ margin: "0px 5px 20px 5px", paddingTop: "2px", paddingBottom: "2px"  }}
			>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					sx={{ flexWrap: "wrap", padding:"8px"}}
				>
					<Stack direction="row" spacing={2} maxWidth='60%'>
						<QuoteNameContainer />
					</Stack>
					<Stack direction={{ lg: "column-reverse", xl: "row" }} spacing={1} sx={{ alignItems: "center", whiteSpace: "nowrap" }}
						divider={
							<Divider orientation="vertical"
								sx={{
									display: { sm: "none", xs: "none", md: "none", lg: "none", xl: "block" },
									color: 'divider',
									background: 'divider',
									height: "16px",
									margin: "8px"
								}}  />
						}
					>
						<Stack direction={"row"} sx={{ alignItems: "center", justifyContent: "end" }} >
							<QuoteDates isLegacyPage={isLegacyPage} />
						</Stack>
						<Stack direction={"row"} sx={{ alignItems: "center", justifyContent: "end" }} >
							<Opportunity isVisible={!IsReadOnly && !hideOpportunityDetails} />
							{isAdmin && <QuoteHeaderCustomization />}
							<QuoteHeaderMenu
								displayEditCustomerDetails={displayEditCustomerDetails}
								hidePdfViewButton={hidePdfViewButton}
								hideOpportunityDetails={hideOpportunityDetails}
							/>
						</Stack>
					</Stack>
				</Stack>
				<Stack
					direction="row"
					justifyContent="start"
					alignItems="normal"
					sx={{
						gap: "8px",
						flexWrap:
						{
							md: "wrap",
							lg: "wrap",
							xl: "nowrap"
						},
						minWidth: 0
					}}
				>
					<CustomerDetails isCollapsed={isCollapsed} />
					{(!isCollapsed && EnableOneTimeTotal && (
						<DonutChart
							subtotal={quote?.Subtotal}
							allTax={quote?.AllTax}
							baseCurrency={quote?.BaseCurrency}
							quoteTotal={quote.QuoteTotal}
							cost={quote.QuoteCost}
							discount={quote.SuggestedTotal}
							hideProfit={hideProfit}
							formatCurrency={getCurrency}
							theme={theme}
						/>
					)) || EnableOneTimeTotal && <OneTimeTotal isCollapsed={isCollapsed} />}
					<QuoteStatistics  isCollapsed={isCollapsed} />

				</Stack>
				<Stack justifyContent="center" alignItems="center">
					<Grid item sx={{ padding: "0 3px", background: theme.palette.icon.primary, borderRadius: "0px 0px 6px 6px" }}>
						<StyledTypographyBold
							data-testid="collapse_toggle"
							sx={{
								display: "flex",
								alignItems: "center",
								cursor: "pointer",
								padding: "4px 0"
							}}
							onClick={toggleCollapse}
						>
						{isCollapsed ?
							<>
								Show More
								<KeyboardArrowDownIcon
									sx={{ fontSize: "16px", color: theme.palette.text.showMore }}
								/>
							</> : <>Show Less
								<KeyboardArrowUpIcon
									sx={{ fontSize: "16px", color: theme.palette.text.showMore }}
								/> </>}
					</StyledTypographyBold>
					</Grid>
				</Stack>
				<EditCustomerDetails
					showEditCustDetails={showEditCustDetails}
					hideEditCustomerDetails={hideEditCustomerDetails}
				/>
			</StyledCard>
		</>
	);
};
