import { useCallback } from "react";

import { useAppGlobalContext } from "../context/globalContext";

const useDataGridBoilerplateHelpers = (props) => {
	const { tabItem } = props;
	const { isDisabledQuoteActions, ckeditor } = useAppGlobalContext();



	const notEditableFields = {
		priceModifier: "Price Modifier",
		recurringPriceModifier: "Recurring Price Modifier",
		overridePriceModifier: "Override Price Modifier",
		overridePrice: "Override Price",
		basePrice: "Base Price",
		extendedPrice: "Extended Price",
		recurringPrice: "Recurring Price",
		recurringBasePrice: "Recurring Base Price",
		suggestedPrice: "Suggested Price",
		bundlePrice: "Bundle Price",
		recurringSuggestedPrice: "Recurring Suggested Price",
		recurringExtendedSuggestedPrice: "Recurring Extended Suggested Price",
		quantity: "Quantity",
		manufacturerPartNumber: "Manufacturer Part Number",
		extendedSuggestedPrice: "Extended Suggested Price",
		gsaPrice: "Gsa Price",
		bundleQty: "Bundle Qty"
	};

	const isBundleItem = (params) =>
		!(
			params.colDef.headerName === notEditableFields.priceModifier ||
			params.colDef.headerName === notEditableFields.recurringPriceModifier ||
			params.colDef.headerName === notEditableFields.overridePriceModifier ||
			params.colDef.headerName === notEditableFields.overridePrice ||
			params.colDef.headerName === notEditableFields.basePrice ||
			params.colDef.headerName === notEditableFields.extendedPrice ||
			params.colDef.headerName === notEditableFields.recurringPrice ||
			params.colDef.headerName === notEditableFields.recurringBasePrice ||
			params.colDef.headerName === notEditableFields.suggestedPrice ||
			params.colDef.headerName === notEditableFields.bundlePrice ||
			params.colDef.headerName === notEditableFields.recurringSuggestedPrice ||
			params.colDef.headerName === notEditableFields.recurringExtendedSuggestedPrice ||
			params.colDef.headerName === notEditableFields.extendedSuggestedPrice ||
			params.colDef.headerName === notEditableFields.gsaPrice ||
			params.colDef.headerName === notEditableFields.bundleQty
		);

	const isInvoiceGroupItem = (params) =>
		!(
			params.colDef.headerName === notEditableFields.overridePrice ||
			params.colDef.headerName === notEditableFields.basePrice ||
			params.colDef.headerName === notEditableFields.extendedPrice ||
			params.colDef.headerName === notEditableFields.recurringPrice ||
			params.colDef.headerName === notEditableFields.recurringBasePrice ||
			params.colDef.headerName === notEditableFields.suggestedPrice ||
			params.colDef.headerName === notEditableFields.bundlePrice ||
			params.colDef.headerName === notEditableFields.recurringSuggestedPrice ||
			params.colDef.headerName === notEditableFields.recurringExtendedSuggestedPrice ||
			params.colDef.headerName === notEditableFields.extendedSuggestedPrice ||
			params.colDef.headerName === notEditableFields.gsaPrice ||
			params.colDef.headerName === notEditableFields.quantity ||
			params.colDef.headerName === notEditableFields.manufacturerPartNumber ||
			params.colDef.headerName === notEditableFields.bundleQty
		);

	const isEditableRowItem = useCallback(
		(params) => {
			if (isDisabledQuoteActions) {
				return false;
			}
			const isBundle = isBundleItem(params);
			const isInvoiceGroup = isInvoiceGroupItem(params);
			if (params.row.TaxCode === "PACKAGE" && params.row.invoiceGroupingRecid == 0) {
				return isBundle;
			}

			if (
				params.row.IsPackageItem &&
				params.colDef.headerName === notEditableFields.quantity
			) {
				return false;
			}

			if (params.row.TaxCode === "PACKAGE" && params.row.invoiceGroupingRecid > 0) {
				return isInvoiceGroup;
			}

			if (
				!params.row.IsPackageItem &&
				params.colDef.headerName === notEditableFields.bundleQty
			) {
				return false;
			}

			return true;
		},
		[isDisabledQuoteActions]
	);

	const handleEdit = useCallback(
		(row) => {
			app.currentModule.loadSubModule("product.edit", {
				container: "quoteModule",
				query: `itemid=${row.idQuoteItems}&idquotetabs=${row.idQuoteTabs}`
			});

			sessionStorage.setItem("cpq_open_tab", tabItem?.IdQuoteTabs);
		},
		[tabItem?.IdQuoteTabs]
	);

	const updateProductInCKEditor = useCallback(() => {
		ckeditor.execute("updateProduct", tabItem?.IdQuoteTabs);
	}, [ckeditor, tabItem?.IdQuoteTabs]);

	return {
		isEditableRowItem,
		handleEdit,
		updateProductInCKEditor
	};
};

export default useDataGridBoilerplateHelpers;
