import { QuoteDeliver } from "js/jsx/src/classes/quote/deliver.jsx";

export default function deliver() {
    if (!app.currentQuote) {
        return;
    }

    quosal.ui.react.render(
        <FormPlaceholder message="Loading Quote Deliver..." />
        , document.getElementById('quoteDeliverContainer'));

    var renderDeliverPage = function (renderMethod) {
        if (!renderMethod)
            renderMethod = quosal.ui.react.update;

        renderMethod(<QuoteDeliver />, document.getElementById('quoteDeliverContainer'));
    };

    var loadDeliverPage = function () {
        renderDeliverPage(quosal.ui.react.render);

        $('#pageName').val('quote.deliver');
    };

    var unbindEvents = function () {
        quosal.navigation.onNavigate.unbind(unbindEvents);
    };

    quosal.navigation.onNavigate.bind(unbindEvents);

    loadDeliverPage();
}