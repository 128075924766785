import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "ckeditor";
import React from "react";
import "../../../../../../../css/CKeditor.css";
import { useAppGlobalContext } from "../context/globalContext";
import { usePreviewContentContext } from "../context/previewContentContext.jsx";
import useEditorWrapperContainer from "./editorWrapperContainer";

const useEditorWrapperRenderer = (showInitialState, handleInitialState, expanded) => {
	const {
		archivedQuoteEditorStyle,
		handleEditorReady,
		postDeletingProduct,
		initStyle,
		wrapperHeight
	} = useEditorWrapperContainer(showInitialState, handleInitialState, expanded);

	const { isDisabledQuoteActions } = useAppGlobalContext();
	const {dogleConfigs, styleConfigs, kickStyleConfig, isLoading} = usePreviewContentContext();

	const initEditorWrapper = (
		<div
			className="ckEditorInitState"
			style={initStyle}
			onClick={() => handleInitialState(false)}
		/>
	);

	const editorBody = (
		<div className="document-editor" style={wrapperHeight}>
			<div className="document-editor__toolbar" />
			<div
				className="document-editor__editable-container"
				style={{ ...archivedQuoteEditorStyle }}
			>
				<div className="document-editor__editable">
					<CKEditor
						onReady={handleEditorReady}
						onError=""
						onChange={(event, editor) => postDeletingProduct(editor)}
						editor={DecoupledEditor}
						data={app.currentQuote?.HTMLContentForAutoSaving}
						disabled={isDisabledQuoteActions}
						config={{dogleConfigs, styleConfigs, kickStyleConfig}}
					/>
				</div>
			</div>
		</div>
	);

	const editorStatus = (
		<div id="editor-status">
			<div id="autosave-status_label">Status:</div>
			<div id="autosave-status_spinner">
				<span id="autosave-status_spinner-label" />
				<span id="snippet-autosave-status_spinner-loader" />
			</div>
		</div>
	);

	return {
		initEditorWrapper,
		editorBody,
		editorStatus,
		isLoading,
	};
};

export default useEditorWrapperRenderer;
