import { Box, Button, IconButton, Modal, Typography, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ModalBox } from "../styledComponents";

export const ConfirmationModal = ({
	isOpen,
	title,
	body,
	handleClose,
	cancelBtnText,
	confirmBtnText,
	onCancel,
	onConfirm
}) => {
	const theme = useTheme();
	return (
		<Modal open={isOpen} onClose={handleClose}>
			<ModalBox>
				<Box display="flex" justifyContent="space-between">
					<Typography fontSize={20} color={theme.palette.text.main}>
						{title}
					</Typography>
					<IconButton
						sx={{
							minWidth: 40,
							maxWidth: 40,
							padding: 0,
							color: theme.palette.link_secondary.main
						}}
						size="medium"
						onClick={handleClose}
					>
						<CloseIcon />
					</IconButton>
				</Box>
				<Typography
					sx={{
						mt: 2,
						fontSize: "14px",
						lineHeight: "20px",
						color: theme.palette.text.main
					}}
				>
					{body}
				</Typography>
				<Box display="flex" justifyContent="space-between" mt={4}>
					<Button
						variant="text"
						sx={{
							padding: 0,
							fontWeight: 600,
							fontSize: "14px",
							color: theme.palette.link_secondary.main,
							textTransform: "none"
						}}
						onClick={onCancel}
					>
						{cancelBtnText}
					</Button>
					<Button
						variant="contained"
						onClick={onConfirm}
						sx={{
							height: "40px",
							borderRadius: "6px",
							fontWeight: 600,
							boxShadow: "none",
							backgroundColor: theme.palette.button_danger.main,
							borderColor: theme.palette.button_danger.main,
							"&:hover": {
								backgroundColor: theme.palette.button_danger.main,
								boxShadow: "none"
							},
							color: theme.palette.text.on_color,
							textTransform: "none"
						}}
					>
						{confirmBtnText}
					</Button>
				</Box>
			</ModalBox>
		</Modal>
	);
};
