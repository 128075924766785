import { createContext, useEffect, useState, useCallback, cloneElement } from "react";
import { createPortal } from "react-dom";

let firstLoadTime = null;
const IdentifyContext = createContext();
export const useIdentifyContext = () => React.useContext(IdentifyContext);

export const IdentifyProvider = () => {
    const [hasIdentifyWarning, setHasIdentifyWarning] = useState(false);
    const [children, setChildren] = useState([]);
    if (!firstLoadTime) {
        firstLoadTime = performance.now();
    }
    useEffect(() => {
            $(function () {
                app.callIdentifyApi({}, setHasIdentifyWarning);
            });
        }, []);
    
    const updateChildren = useCallback(() => {
        setChildren((prevChildren) => {
            const newChildren = [...prevChildren];
            newChildren.forEach((child) => {
                child.key = Math.random();
            });
            return newChildren;
        });
    }, [children]);

    const applyUpdatedSettings = useCallback(() => {
        updateChildren();
        setHasIdentifyWarning(false);
    }, [updateChildren]);

    useEffect(() => {
        if (hasIdentifyWarning && (performance.now() - firstLoadTime) < 2000) {
            updateChildren();
            setHasIdentifyWarning(false);
        }
    }, [hasIdentifyWarning]);
    
    const removeAllChildren = useCallback(() => {
        setChildren([]);
    }, []);

    const removeChild = useCallback((domNode) => {
        if (!domNode) {
            return;
        }
        setChildren((children) => {
            const childrenToRemove = [];
            for (var i = 0; i < children.length; i++) {
                var parent = children[i].domNode;
                while (parent != null) {

                    if (parent == domNode) {
                        childrenToRemove.push(children[i]);
                        try {
                            if (children[i].component && children[i].component.dispose) {
                                children[i].component.dispose();
                            }
                        } catch (ex) {
                            quosal.log.error(ex);
                        }
                        break;
                    }
                    parent = parent.parentElement;
                }
            }
            const newChildren = [...children];
            childrenToRemove.forEach((child) => {
                const index = newChildren.findIndex((c) => c === child);
                if (index !== -1) {
                    newChildren.splice(index, 1);
                }
            });
            return newChildren;
        });
    }, []);


    const renderChild = useCallback((jsx, domNode, reload=false) => {
        let updatedChild = null;
        if (!domNode) {
            return;
        }

        setChildren((prevChildren) => {
            const index = prevChildren.findIndex((child) => child.domNode === domNode)
            if (index === -1) {
                return [...prevChildren, { jsx, domNode, key: Math.random() }];
            } else {
                const child = prevChildren[index];
                child.component?.forceUpdate();
                const newChildren = [...prevChildren];
                child.jsx = jsx;
                if(reload){
                    child.key = Math.random();
                }
                updatedChild = child.component;
                return newChildren;
            }
        });
        return updatedChild;
    }, []);

    quosal.ui.react.identifyContextRenderChild = renderChild;
    quosal.ui.react.identifyContextRemoveChild = removeChild;
    quosal.ui.react.identifyContextRemoveAlChildren = removeAllChildren;

    return <IdentifyContext.Provider value={{
        hasIdentifyWarning,
        applyUpdatedSettings
    }}>{children.map((child) => {
        const jsx = cloneElement(child.jsx, {
            ref: (comp) => {
                child.component = comp;
            }
        });
        return createPortal(jsx, child.domNode, child.key)
    }
    )}
    </IdentifyContext.Provider>;
};

IdentifyProvider.Consumer = IdentifyContext.Consumer;


