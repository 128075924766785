import etilize from "js/jsx/src/controllers/cloud/etilize.jsx";
import attachSite from "js/jsx/src/controllers/crm/attachSite.jsx";
import attachsites from "js/jsx/src/controllers/crm/attachsites.jsx";
import opportunity from "js/jsx/src/controllers/crm/opportunity.jsx";
import edit from "js/jsx/src/controllers/product/edit.jsx";
import search from "js/jsx/src/controllers/product/search.jsx";
import attachOpportunity from "js/jsx/src/controllers/quote/attachOpportunity.jsx";
import content from "js/jsx/src/controllers/quote/content.jsx";
import copyitem from "js/jsx/src/controllers/quote/copyitem.jsx";
import customer from "js/jsx/src/controllers/quote/customer.jsx";
import customerdetails from "js/jsx/src/controllers/quote/customerdetails.jsx";
import customersearch from "js/jsx/src/controllers/quote/customersearch.jsx";
import dashboard from "js/jsx/src/controllers/quote/dashboard.jsx";
import deliver from "js/jsx/src/controllers/quote/deliver.jsx";
import quoteHome from "js/jsx/src/controllers/quote/home.jsx";
import pickTemplate from "js/jsx/src/controllers/quote/pickTemplate.jsx";
import publish from "js/jsx/src/controllers/quote/publish.jsx";
import review from "js/jsx/src/controllers/quote/review.jsx";
import quoteSearch from "js/jsx/src/controllers/quote/search.jsx";
import agreementMapping from "js/jsx/src/controllers/settings/agreementMapping.jsx";
import approvalsetup from "js/jsx/src/controllers/settings/approvalsetup.jsx";
import approval from "js/jsx/src/controllers/quote/approval.jsx";
import approvalgroups from "js/jsx/src/controllers/settings/approvalgroups.jsx";
import approvalrules from "js/jsx/src/controllers/settings/approvalrules.jsx";
import ciscoSetup from "js/jsx/src/controllers/settings/ciscoSetup.jsx";
import customizeFields from "js/jsx/src/controllers/settings/customizeFields.jsx";
import taxServiceSetup from "js/jsx/src/controllers/settings/taxServiceSetup.jsx";
import microUiSetup from "js/jsx/src/controllers/settings/microUiSetup.jsx";
import outputGridLayoutEditor from "js/jsx/src/controllers/settings/outputGridLayoutEditor.jsx";
import vendors from "js/jsx/src/controllers/settings/vendors.jsx";
import tabEdit from "js/jsx/src/controllers/tab/edit.jsx";
import home from "js/jsx/src/controllers/home.jsx";
import ingramQuoteSearch from "js/jsx/src/controllers/ingram/ingramQuoteSearch.jsx";
import arrowQuoteSearch from "js/jsx/src/controllers/arrow/arrowQuoteSearch.jsx";
import arrowTermImport from "js/jsx/src/controllers/arrow/arrowTermImport.jsx";
import salesTracks from "js/jsx/src/controllers/settings/guidedSelling/salesTracks.jsx";
import submittedForms from "js/jsx/src/controllers/settings/guidedSelling/submittedForms.jsx";
import salesTracksSearch from "js/jsx/src/controllers/product/salestracks.jsx";
import salesTracksForms from "js/jsx/src/controllers/product/salestracksforms.jsx";
import quoteHeaderWrapper from "js/jsx/src/controllers/quote/quoteHeaderWrapper.jsx";
global.Controllers = {
    etilize,
    attachSite,
    attachsites,
    opportunity,
    edit,
    search,
    attachOpportunity,
    content,
    copyitem,
    customer,
    customerdetails,
    customersearch,
    dashboard,
    deliver,
    quoteHome,
    pickTemplate,
    publish,
    review,
    quoteSearch,
    agreementMapping,
    approvalsetup,
    approvalgroups,
    approvalrules,
    approval,
    ciscoSetup,
    customizeFields,
    outputGridLayoutEditor,
    vendors,
    tabEdit,
    home,
    ingramQuoteSearch,
    arrowQuoteSearch,
    arrowTermImport,
    taxServiceSetup,
    microUiSetup,
    salesTracks,
    submittedForms,
    salesTracksSearch,
    salesTracksForms,
    quoteHeaderWrapper
};