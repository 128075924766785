quosal.api.quote = {};

quosal.api.quote.search = function (criteria, pageSize, pageNumber, enumFields, noteFields) {
    return quosal.api.call('Quote.Search', {
        criteria: criteria,
        pageSize: pageSize,
        pageNumber: pageNumber,
        enumFields: enumFields,
        noteFields: noteFields,
        isAsyncAction: true
    });
};

quosal.api.quote.exportquote = function (columns, criteria, totalQuoteNumber, enumFields, noteFields, idQuoteMains) {
    return quosal.api.call('Quote.QuoteExport', {
        columns: columns,
        criteria: criteria,
        totalQuoteNumber: totalQuoteNumber,
        enumFields: enumFields,
        noteFields: noteFields,
        idQuoteMains: idQuoteMains,
        isAsyncAction: true
    });
};

quosal.api.quote.quickSearch = function (input) {
    return quosal.api.call('Quote.QuickSearch', {
        input: input
    });
};

quosal.api.quote.openQuote = function (idQuoteMain, dataView) {
    var isEnableUnifiedProductSearch = quosal.settings.getValue('UseUnifiedProductSearch');
    if(isEnableUnifiedProductSearch) {
        quosal.sell.product.loadPriceSourcing(false, () => {
            sessionStorage.setItem("priceSources", JSON.stringify(quosal.sell.product.sources));
            quosal.sell.product.getCatalogs(idQuoteMain, quosal.sell.product.sources);
        });
    }
    return quosal.api.call('Quote.OpenQuote', {
        idQuoteMain: idQuoteMain,
        dataView: dataView
    });
};

quosal.api.quote.copyQuoteAsNewVersion = function (idQuoteMain, quoteVersionSource) {
    return quosal.api.call('Quote.CopyQuoteAsNewVersion', {
        idQuoteMain: idQuoteMain,
        quoteVersionSource: quoteVersionSource
    });
};

quosal.api.quote.duplicateTab = function (idQuoteMain, idQuoteTabs, asRadioOption, addTabTemplate) {
    return quosal.api.call('Quote.DuplicateTab', {
        idQuoteMain: idQuoteMain,
        idQuoteTabs: idQuoteTabs,
        asRadioOption: asRadioOption || false,
        addTabTemplate: addTabTemplate || false
    });
};

quosal.api.quote.removeZeroQuantityItems = function (idQuoteMain, idQuoteTabs) {
    return quosal.api.call('Quote.RemoveZeroQuantityItems', {
        idQuoteMain: idQuoteMain,
        idQuoteTabs: idQuoteTabs
    });
};

quosal.api.quote.removeCustomers = function (idQuoteMain) {
    return quosal.api.call('Quote.RemoveCustomers', {
        idQuoteMain: idQuoteMain
    });
};

quosal.api.quote.prepareForPublish = function (idQuoteMain, initializePublishResources) {
    return quosal.api.call('Quote.PrepareForPublish', {
        idQuoteMain: idQuoteMain,
        initializePublishResources: initializePublishResources
    });
};

//params: idQuoteMain, isPreview, saveOnly, docType, orderPorterTemplate, attachedDocuments, attachedVideos, updatedTabBooleans
quosal.api.quote.publish = function (params) {
    return quosal.api.call('Quote.Publish', params);
};

quosal.api.quote.generateCKEditorPDF = function (params) {
    return quosal.api.call('Quote.GenerateCKEditorPDF', params);
};

quosal.api.quote.attachPersonalVideo = function (idQuoteMain, videoUrl) {
    return quosal.api.call('Quote.AttachPersonalVideo', {
        idQuoteMain: idQuoteMain,
        videoUrl: videoUrl
    });
};

quosal.api.quote.deletePersonalVideo = function (idQuoteMain) {
    return quosal.api.call('Quote.DeletePersonalVideo', {
        idQuoteMain: idQuoteMain
    });
};

quosal.api.quote.updateFromSpreadsheets = function (idQuoteMain) {
    return quosal.api.call('Quote.UpdateFromSpreadsheets', {
        idQuoteMain: idQuoteMain
    });
};

quosal.api.quote.expireCache = function (idQuoteMain) {
    return quosal.api.call('Quote.ExpireCache', {
        idQuoteMain: idQuoteMain
    });
};

quosal.api.quote.repairQuote = function (idQuoteMain) {
    return quosal.api.call('Quote.RepairQuote', {
        idQuoteMain: idQuoteMain
    });
};

quosal.api.quote.newVersion = function (idQuoteMain, versionComment) {
    return quosal.api.call('Quote.NewVersion', {
        idQuoteMain: idQuoteMain,
        versionComment: versionComment
    });
};

quosal.api.quote.beginImportQuote = function (fileName) {
    return quosal.api.call('Quote.BeginImportQuote', {
        fileName: fileName
    });
};

quosal.api.quote.approveQuote = function (idQuoteMain) {
    return quosal.api.call('Quote.ApproveQuote', {
        idQuoteMain: idQuoteMain
    });
};

quosal.api.quote.copyQuote = function (idQuoteMain, quoteStatus, bypassCanCopyQuote) {
    return quosal.api.call('Quote.CopyQuote', {
        idQuoteMain: idQuoteMain,
        quoteStatus: quoteStatus,
        bypassCanCopyQuote: bypassCanCopyQuote
    });
};

quosal.api.quote.checkDocuSignApproval = function(idQuoteMain, version, envelopeId, quoteReadableId) {
    return quosal.api.call('Quote.CheckDocuSignApproval', {
        idQuoteMain: idQuoteMain,
        version: version,
        envelopeId: envelopeId,
        quoteReadableId: quoteReadableId
    });
};

quosal.api.quote.checkIsOrderPorterApproved = function(orderPorterPasscode) {
    return quosal.api.call('Quote.CheckIsOrderPorterApproved', {
        orderPorterPasscode: orderPorterPasscode
    });
};

quosal.api.quote.checkOrderPorterApproval = function (idQuoteMain, forceDownload, quoteReadableId, version, orderPorterPasscode) {
    return quosal.api.call('Quote.CheckOrderPorterApproval', {
        idQuoteMain: idQuoteMain,
        forceDownload: forceDownload,
        quoteReadableId: quoteReadableId,
        version: version,
        orderPorterPasscode: orderPorterPasscode        
    });
};

quosal.api.quote.checkManagerApproval = function (idQuoteMain, quoteReadableId, version) {
    return quosal.api.call('Quote.CheckManagerApproval', {
        idQuoteMain: idQuoteMain,
        quoteReadableId: quoteReadableId,
        version: version
    });
};

quosal.api.quote.getQuoteTemplates = function(){
    return quosal.api.call('Quote.GetQuoteTemplates', { 
        isAsyncAction: true,
    });
};

quosal.api.quote.loadReviewHistory = function (idReadable, idMain) {
    return quosal.api.call('Quote.LoadReviewHistory', {
        idQuoteReadable: idReadable,
        idQuoteMain: idMain
    });
};
quosal.api.quote.loadReviewConfigs = function (idMain) {
    return quosal.api.call('Quote.LoadReviewConfigs', { 
        idQuoteMain: idMain
       });
};

quosal.api.quote.loadReviewPageScripts = function (idQuoteMain) {
    return quosal.api.call('Quote.LoadReviewPython', {
        idQuoteMain: idQuoteMain
    });
};

quosal.api.quote.requestReview = function (idMain, emails, subject, body, forceFallback, filePath, isEditorOutput) {
    var encoded = quosal.util.b64EncodeUnicode(body);
    return quosal.api.call('Quote.RequestReview', { 
        idQuoteMain: idMain,
        subject: subject,
        forceFallbackEmail: forceFallback,
        pdfFile: filePath,
        sendto: emails,
        bodyhtml: encoded,
        isEditorOutput: isEditorOutput
       });
};

quosal.api.quote.requestApproval = function (idMain, emails, subject, body, forceFallback, filePath, isEditorOutput) {
    var encoded = quosal.util.b64EncodeUnicode(body);
    return quosal.api.call('Quote.RequestApproval', { 
        idQuoteMain: idMain,
        subject: subject,
        sendto: emails,
        forceFallbackEmail: forceFallback,
        pdfFile: filePath,
        bodyhtml: encoded,
        isEditorOutput: isEditorOutput
       });
};

quosal.api.quote.availableFields = function () {
    return quosal.api.call('Quote.AvailableFields', {});
};

quosal.api.quote.pastePreviewBlock = function (rawPaste) {
    var encoded = quosal.util.b64EncodeUnicode(rawPaste);
    return quosal.api.call('Quote.PastePreviewBlock', {
        PasteData: encoded
    });
};

quosal.api.quote.pasteLoadFieldMappings = function () {
    return quosal.api.call('Quote.LoadFieldMappings', {});
};

quosal.api.quote.pasteSaveFieldMappings = function (name, mappings, isDefault, originalname) {
    return quosal.api.call('Quote.SaveFieldMappings', {
        mappingname: name,
        originalmappingname: originalname,
        mappingdesc: mappings.description,
        isdefaultmap: isDefault,
        includeheaders: mappings.includeheaders,
        lookupetilize: mappings.lookupetilize,
        ignorezeroqty: mappings.ignorezeroqty,
        spacepackage: mappings.spacepackage,
        overwrite: mappings.overwrite,
        mappedcolumns: mappings.mappedcolumns
    });
};

quosal.api.quote.SaveTemplateGroupings = function (templates) {
    return quosal.api.call('Quote.SaveTemplateGroupings', {
        templates: templates
    });
};

quosal.api.quote.pasteDeleteFieldMappings = function (name) {
    return quosal.api.call('Quote.DeleteFieldMappings', {
        name: name
    });
};

quosal.api.quote.pasteSaveQuoteBlock = function (rawPaste, quoteinfo, settings, updatesettings, packagesettings, mappings) {
    var encoded = quosal.util.b64EncodeUnicode(rawPaste);
    return quosal.api.call('Quote.PasteSaveQuoteBlock', {
        PasteData: encoded,
        idquotemain: quoteinfo.quoteid,
        selectedidquotetabs: quoteinfo.tabs,

        includeheaders: settings.includeheaders,
        lookupetilize: settings.lookupetilize,
        createpackage: settings.createpackage,
        ignorezeroqty: settings.ignorezeroqty,
        specialOrder: settings.specialOrder,

        updateonly: settings.updateonly,
        updatecost: updatesettings.updatecost,
        updatesuggested: updatesettings.updatesuggested,
        updatequantity: updatesettings.updatequantity,
        createnew: updatesettings.createnew,

        createtabs: settings.createtabs,
        Source: settings.staticSource,
        PriceModifier: settings.staticPriceMod,
        CostModifier: settings.staticCostMod,

        spacepackage: settings.spacepackage,
        
        PackagePartNumber: packagesettings.PackagePartNumber,
        PackageDescription: packagesettings.PackageDescription,
        PackageQuantity: packagesettings.PackageQuantity,
        
        mappedColumns: mappings,
        externalQuoteNumber: settings.externalQuoteNumber,
        externalQuoteExpiration: settings.externalQuoteExpiration,
    });
};

quosal.api.quote.loadApprovalRules = function (name) {
    return quosal.api.call('Quote.LoadApprovalRules', {
        name: name
    });
};

quosal.api.quote.unarchiveQuote = function (idQuoteMain) {
    return quosal.api.call('Quote.UnarchiveQuote', {
        idQuoteMain: idQuoteMain
    });
};

quosal.api.quote.forceUpdate = function (idQuoteMain) {
    return quosal.api.call('Quote.ForceUpdate', {
        idQuoteMain: idQuoteMain
    });
};

quosal.api.quote.calculateTax = function (idQuoteMain) {
    return quosal.api.call('Quote.CalculateTax', {
        idQuoteMain: idQuoteMain
    });
}

quosal.api.quote.submitGreatAmericaCreditApplication = function (idQuoteMain) {
    return quosal.api.call('Quote.SubmitGreatAmericaCreditApplication', {
        idQuoteMain: idQuoteMain
    });
}

quosal.api.quote.submitArrowCreditRequest = function (idQuoteMain) {
    return quosal.api.call('Quote.SubmitArrowCreditRequest', {
        idQuoteMain: idQuoteMain
    });
}