import {QuoteContentGrid} from "js/jsx/src/classes/quote/contentGrid.jsx";
import {ConfigMenu, ActionsMenu} from "js/jsx/src/classes/menus.jsx";

export class TabControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            selectedTabIndex: 0,
            tabsSelected: {}
        };
        var selectedTab = this.getSelectedTab();
        this.state.tabsSelected[selectedTab.tabId] = selectedTab.tabId;
        this.state.selectedTabIndex = selectedTab.uiIndex;

        // This binding is necessary to make `this` work in the callback
        this.fixTabs = this.fixTabs.bind(this);
        this.initTabs = this.initTabs.bind(this);
        this.getSelectedTab = this.getSelectedTab.bind(this);
    }
    componentDidMount() {
        this.initTabs();
    }
    fixTabs(){
        this.tabs = $(this.refs.tabRoot).tabs('destroy');
        this.initTabs();
    }
    initTabs() {
        var tabOptions = {
            active: this.getSelectedTab().uiIndex,
            beforeActivate: function (e, ui) {
                if(ui.newTab.index() > -1){
                    var selectedTabId = ui.newTab[0].dataset.key;
                    if(selectedTabId){
                        this.state.tabsSelected[selectedTabId] = selectedTabId;
                        this.props.appModule.setState({selectedTab: selectedTabId})
                    }
                    this.setState({selectedTabIndex: ui.newTab.index() })
                    if(this.props.options.beforeActivate){
                        return this.props.options.beforeActivate(this, e, ui);
                    }
                }
            }.bind(this),
            activate: function (e, ui) {
                $(window).resize();
                if(this.props.options.activate)
                    return this.props.options.activate(this, e, ui);
            }.bind(this)
        };
        this.tabs = $(this.refs.tabRoot).tabs(tabOptions);

        var sortRoot = $(this.refs.tabRoot).find('.ui-tabs-nav').first();
        sortRoot.data('tabControl', this);

        sortRoot.droppable({
            accept: 'tr',
            hoverClass: 'drop-highlight'
        });

        sortRoot.sortable({
            handle: '.tab-dragger',
            helper: function(e, ui) {
                var c = ui.clone();
                c.removeAttr('data-reactid'); //react doesn't like duplicate data-reactid's. not one bit.
                c.find('*').removeAttr('data-reactid');
                return c;
            },
            items: 'li.tab.selectable',
            distance: 10,
            containment: '.ui-tabs-nav',
            opacity:.7,
            start: function (e, ui) {
                var tabControl = ui.item.parents('.ui-tabs-nav').first().data('tabControl');

                tabControl.sortStart = ui.item.index();
            },
            stop: function (e, ui) {
                var tabControl = ui.item.parents('.ui-tabs-nav').first().data('tabControl');
                var sortStart = tabControl.sortStart;
                var sortEnd = ui.item.index();

                delete tabControl['sortStart'];

                if(sortStart == sortEnd)
                    return;

                //cancel the sort operation or react DOM will become out of sync, do the sorting in react below
                $(ui.item.parents('.ui-tabs-nav').first()).sortable("cancel");

                var tab = tabControl.props.tabs[sortStart];

                //shift current tab selection based on movement
                if(tabControl.state.selectedTabIndex == sortStart)
                    tabControl.state.selectedTabIndex = sortEnd;
                else if(sortEnd > sortStart && (tabControl.state.selectedTabIndex > sortStart && tabControl.state.selectedTabIndex <= sortEnd))
                    tabControl.state.selectedTabIndex--;
                else if(sortEnd < sortStart && (tabControl.state.selectedTabIndex < sortStart && tabControl.state.selectedTabIndex >= sortEnd))
                    tabControl.state.selectedTabIndex++;

                //notify outer scope of sort event
                if (tabControl.props.onSort) {
                    tabControl.props.onSort(tabControl, tab, sortStart, sortEnd);
                } else {
                    tab.index = sortEnd;
                    tabControl.forceUpdate();
                }
            }
        });
        $("#contentGridTabs").tabs("option", "active", this.getSelectedTab().uiIndex);
    }
    menuItemSelected(menu, action) {
        if(action.callback) {
            action.callback(menu, action);
        } else if(action.url) {
            quosal.navigation.navigate(action.url);
        }
    }
    getSelectedTab() {
        var selectedTab = this.props.tabs[this.state.selectedTabIndex];
        if(app && app.currentModule && app.currentModule.Type == "QuoteDashboard"){
            var quoteNavigationModuleIndex = quosal.util.findWithAttr(app.currentModule.subModules, "Type", "QuoteNavigation");
            if(quoteNavigationModuleIndex > -1){
                var subModule = app.currentModule.subModules[quoteNavigationModuleIndex];
                var selectedTabId = null;
                if(subModule && subModule.state && subModule.state.selectedTab){
                    selectedTabId = subModule.state.selectedTab;
                }
                if(app.currentQuote && app.currentQuote.Tabs && selectedTabId){
                    selectedTab = this.props.tabs[quosal.util.findWithAttr(this.props.tabs, "tabId", selectedTabId)];
                } 
                if(!selectedTab){
                    selectedTab = this.props.tabs[this.state.selectedTabIndex];
                }
            }
        }
        return  selectedTab
    }
    render() {
        var buttons = this.props.buttons || [];
        var panels = this.props.panels || [];

        this.props.tabs.sort((a, b) => {
            return a.index - b.index;
        });

        if(this.props.tabs) {
            for (var i = 0; i < this.props.tabs.length; i++) {
                buttons.push(<TabButton ref={'button' + i} tabControl={this} key={this.props.tabs[i].tabId} {...this.props.tabs[i]} />);
                panels.push(<TabPanel ref={'panel' + i} tabControl={this} key={this.props.tabs[i].tabId} {...this.props.tabs[i]}>{this.props.tabs[i].panel}</TabPanel>);
            }
        }
        var me = this;
        return (
            <div ref="tabRoot" className="tabs" id="contentGridTabs">
                <ul ref="sortRoot">
                    {buttons}
                </ul>
                {panels.filter(function(panel){return Object.keys(me.state.tabsSelected).map(function(key){return key}).includes(panel.key)})}
            </div>
        );
    }
}


class TabButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {   
            isLoading: false,
        }; 
        // This binding is necessary to make `this` work in the callback
        this.renameLostFocus = this.renameLostFocus.bind(this);
        this.renameTextKeyPress = this.renameTextKeyPress.bind(this);
        this.shouldComponentUpdate = this.shouldComponentUpdate.bind(this);
    }

    renameLostFocus() {
        if(this.props.item.editMode) {
            this.props.item.editMode = false;

            var afterUpdate = function(msg) {
                quosal.sell.quote.updateFromApiResponse(msg);
                this.forceUpdate();
                if (msg.error) {
                    Dialog.open({
                        title: 'ERROR',
                        message: msg.error,
                        links: [{
                            title: 'OK',
                            callback: function callback() {
                                var action = this.props.menu.actions[0];
                                action.callback(this.props.menu, action);
                                Dialog.close();
                            }.bind(this)
                        }]
                    });
                }
            }.bind(this);

            if(this.refs.renameText.value != this.props.item.TabName) {
                this.props.item.isUnsaved = true;
                this.props.item.TabName = this.refs.renameText.value

                this.forceUpdate();

                var updateApi = quosal.api.data.update({
                    fields: {
                        TabName: this.refs.renameText.value
                    },
                    queries: [{
                        table: 'QuoteTabs',
                        where: [{
                            field: 'IdQuoteTabs',
                            operator: 'Equals',
                            value: this.props.item.IdQuoteTabs
                        }]
                    }]
                }, this.props.item.IdQuoteMain);
                updateApi.finished = function(msg) {
                    afterUpdate(msg);
                }.bind(this);
                updateApi.call();
            }
        }

        this.forceUpdate();
    }
    renameTextKeyPress(e) {
        if(e.keyCode == 13) { //enter
            this.refs.renameText.blur();
        } else if(e.which == 27) { //escape
            this.props.item.editMode = false;
            this.refs.renameText.blur();
        }
    }
    componentDidUpdate() {
        if(this.props.item && this.props.item.editMode) {
            setTimeout(function() {
                this.refs.renameText.select();
            }.bind(this), 1);
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        //SP 2/15/18 9502706: If this is not the currently selected tab do not update.
        // selectedTabIndex is zero based, index is one based.
        //9829908: Or if the index of the tab has changed, from drag and drop.
         //9839119: Hidden tabs to the left of a currently selected tab are not included while calcualting its index.
        //         I am going to return true here for now. There is only a minor effect on performance always updating here. 
        return(
            // this.props.tabControl.state.selectedTabIndex == this.props.index - 1 || 
            // this.props.index != nextProps.index 
            true
        );
    }
    render() {
        var menuElem = null;
        var dragHandle = null;
        var shouldDisplayTabNumbers = quosal.settings.getValue('displayTabNumbers', true);
       
        if(this.props.item && this.props.item.isUnsaved) {
            menuElem = <Spinner style={{marginLeft:-16, marginBottom:-16}} />;
        }
        else if(this.state && this.state.isLoading){
            menuElem = <Spinner style={{marginLeft:-16, marginBottom:-16}} />;
        } else if(this.props.menu && !this.props.item.editMode) {
            if (this.props.menu.type == 'ActionsMenu') {
                menuElem = <ActionsMenu {...this.props.menu} tabId={this.props.tabId} onClick={this.props.onClick}/>;
            } else if (this.props.menu.type == 'ConfigMenu') {
                menuElem = <ConfigMenu {...this.props.menu} onClick={this.props.onClick}/>;
            }
        }

        if(this.props.item && !this.props.item.editMode && (!this.props.item.IsProtectedTab || quosal.util.userIsAdminOrMaintainer()) ) {
            dragHandle = <div className="tab-dragger" />;
        }

        var tabLabel;

        var onClick = null;
        if (this.props.item && this.props.item.GoToProductSearchWhenTabIsEmpty &&
            this.props.panel && this.props.panel.props.editable &&
            this.props.panel.props.rows && this.props.panel.props.rows.length === 0) {
            onClick = function () {
                QuoteContentGrid.goToProductSearch(this.props.tabId);
            }.bind(this);
        }

        if(this.props.item && this.props.item.editMode) {
            tabLabel =
                <a ref="link" href={this.props.tabId ? ('#' + this.props.tabId) : null} onClick={onClick} style={{display:'none'}}></a>
        } else {
            tabLabel = <a ref="link" href={this.props.tabId ? ('#' + this.props.tabId) : null} onClick={onClick} style={this.props.labelStyle}>
                {(this.props.tabControl.props.numberTabs && this.props.panel && shouldDisplayTabNumbers) ? (this.props.index + ') ') : ''} {this.props.item ? this.props.item.TabName : this.props.tabName} {this.props.tabContent}
            </a>;
        }
        var tabColor = this.props.item && this.props.item.TabColor ? ' tabcolor ' + this.props.item.TabColor : '';
        var tabId = this.props.item && this.props.item.IdQuoteTabs ? this.props.item.IdQuoteTabs : null;
        return (
            <li className={'tab ' + (this.props.panel ? 'selectable' : '') + tabColor } data-key={tabId} title={this.props.title || ''} style={this.props.tabStyle}>
                {dragHandle}
                {(this.props.item && this.props.item.editMode) ? null : this.props.preLabel}
                {tabLabel}
                {(this.props.item && this.props.item.editMode) ? <input type="text" defaultValue={this.props.tabName} className="tab-rename-input" ref="renameText" onBlur={this.renameLostFocus} onKeyDown={this.renameTextKeyPress} /> : null}
                {this.props.postLabel}
                {menuElem}
            </li>
        );
    }
}


class TabPanel extends React.Component {
    render() {
        return (
            <div id={this.props.tabId}>
                {this.props.children}
            </div>
        );
    }
}