import { v4 as uuidv4 } from "uuid";

export const ENUM_KEY_SELECT = 'select';
export const ENUM_KEY_RADIO = 'radio';
export const ENUM_KEY_NUMBER = 'number';
export const ENUM_KEY_TEXT = 'text';
export const ENUM_KEY_CHECKBOX = 'checkbox';
export const ENUM_KEY_DATE = 'date';


export const ENUM_DATA_NAME = {
    [ENUM_KEY_SELECT]: 'select',
    [ENUM_KEY_RADIO]: 'radio',
    [ENUM_KEY_NUMBER]: 'number',
    [ENUM_KEY_TEXT]: 'text',
    [ENUM_KEY_CHECKBOX]: 'checkbox',
    [ENUM_KEY_DATE]: 'date',
};


export const DATA_TYPE_OPTIONS = [
    {
        label: 'Dropdown',
        value: ENUM_DATA_NAME[ENUM_KEY_SELECT],
    },
    {
        label: 'Radio',
        value: ENUM_DATA_NAME[ENUM_KEY_RADIO],
    },
    {
        label: 'Numeric',
        value: ENUM_DATA_NAME[ENUM_KEY_NUMBER],
    },
    {
        label: 'Text',
        value: ENUM_DATA_NAME[ENUM_KEY_TEXT],
    },
    {
        label: 'Checkbox',
        value: ENUM_DATA_NAME[ENUM_KEY_CHECKBOX],
    },
    {
        label: 'Date',
        value: ENUM_DATA_NAME[ENUM_KEY_DATE],
    },
]

export const randomId = () => {
    function randomString() {
        let uniqueId = uuidv4();
        return uniqueId;
    }
    return randomString();
}

export const COMMON_GRID_PADDING = '16px 0';
export const TWO_COLUMS_LGRID_PADDING = '16px 20px 16px 0';
export const DIVIDER_STYLE = '1px solid #ECECF0';

export const CUSTOM_SX_FORM_BUILDER_CONTAINER = {
    height: { xs: '78vh', sm: '100%', md: '65vh', lg: '73vh', xl: '78vh', },
    overflow: 'hidden',
}

export const SECTION_NAME_CHARACTER_LIMIT = 100;
export const QUESTION_FIELD_CHARACTER_LIMIT = 100;
export const TOOLTIP_FIELD_CHARACTER_LIMIT = 50;
export const INPUT_VALUE_CHARACTER_LIMIT = 50;
export const MAX_INPUT_VALUES = 10;
export const MIN_NUMERIC_VALUE = 1;
export const MAX_INT_VALUE = 2147483647; 

export const ANSWERS_INITIAL_STATE = [{
    answer: '',
    sortOrder: 1,
    products: [],
    isRepeated: false,
    isEmpty: false,
},
{
    answer: '',
    sortOrder: 2,
    products: [],
    isRepeated: false,
    isEmpty: false,
},
];

export const DATA_TYPE_INITIAL_STATE = {
    ['OPTION_TYPE']: {
        answers: ANSWERS_INITIAL_STATE,
        mappedAnswers: [],
        dynamicQuesConditions:[]
    },
    ['CHECKBOX-TYPE']: {
        answers: ANSWERS_INITIAL_STATE,
        mappedAnswers: [],
        minOptions: '',
        hasMinOptions: false,
    },
    ['NUMBER_RANGE']: {
        "isMultiplier": false,
        "minValue": '',
        "maxValue": '',
        "multipliers": [],
    },
    ['DATE_TYPE']: {
        "from": null,
        "to": null,
    },
}

export const QUESTION_STRUCTURE = {
    question: '',
    isRequired: true,
    isActive: true,
    isDynamicQuestion: false,
    tooltip: '',
    answerDataType: '',
    sortOrder: 1,
    questionId: randomId(),
    ...DATA_TYPE_INITIAL_STATE['OPTION_TYPE'],
}

export const SECTION_STRUCTURE = {
    name: '',
    sortOrder: 1,
    questions: [
        QUESTION_STRUCTURE,
    ]
}


export const arrayItemUpdater = (arrayToUpdate, indexToUpdate, objectToUpdate) => {
    return arrayToUpdate.map((object, index) => index === indexToUpdate ? objectToUpdate : object);
}

export const handleValidateDate = (value, from, to) => {
    if (!!value) {
        if (!!value.date() === false) {
            return INVALID_DATE_FORMAT_ERROR_MESSAGE;
        }
        if (!!from && !!to && !!from.date() && !!to.date()) {
            if (from > to) {
                return FROM_DATE_HIGHER_TO_DATE_ERROR_MESSAGE;
            } else if (from < to) {
                return null;
            } else {
                return SAME_DATE_FORMAT_ERROR_MESSAGE;
            }
        }
    }
    return null;
}

export const MANDATORY_FIELD_HELPER_TEXT = 'In order to submit the form please fill the required field'

export const SECTION_NAME_LIMIT_HELPER_TEXT = 'Section Name must not exceed 100 characters' 

export const INPUT_LABEL_LIMIT_HELPER_TEXT = 'Input Label/Question must not exceed 100 characters'

export const TOOLTIP_LIMIT_HELPER_TEXT = 'Tooltip must not exceed 100 characters'

export const INPUT_VALUE_LIMIT_HELPER_TEXT = 'Input Value must not exceed 75 characters'

export const DUPLICATED_FIELD_ERROR = 'Duplicate input value entered'

export const EMPTY_FIELD_ERROR = 'Invalid input value entered'

export const MANUFACTURER_PART_NUMBER_VALIDATION_ERROR = 'Invalid manufacturer part number entered'

export const INPUT_FIELD_PRODUCTS_MESSAGE = 'Please delete the mapping to edit the input value'

export const DELETE_BUTTON_LINKED_FIELD_MESSAGE = 'Associated to Update Quantity for Input Label/Question'

export const ATTACHED_DYNAMIC_QUESTION_MESSAGE = 'Associated with Dynamic Condition for Input Label/Question'

export const ATTACHED_DYNAMIC_QUESTION_INPUT_VALUE_MESSAGE = 'Associated with Dynamic Condition'

export const MAX_VALUE_ERROR_MESSAGE = 'Min Value cannot be greater than Max Value'

export const EQUALS_LIMITS_VALUES_ERROR_MESSAGE = 'Min Value cannot be same as Max Value'

export const MIN_OPTIONS_HIGH_EQUAL_ERROR_MESSAGE = 'The minimum options cannot be higher or equal to the number of questions'

export const INVALID_DATE_FORMAT_ERROR_MESSAGE = 'Please enter a valid date'

export const SAME_DATE_FORMAT_ERROR_MESSAGE = 'From date cannot be same as To date'

export const FROM_DATE_HIGHER_TO_DATE_ERROR_MESSAGE = 'To date cannot be before From date'

export const INACTIVE_GS_FORM = 'If you set the Sales track to inactive, this form will be disabled for the Sales Reps to submit'

export const ADD_QUOTE_TEMPLATE = "Add Quote Templates to Launch This Sales Track"

export const ADD_TO_QUOTE_TEMPLATE = 'Add to Quote Template'    
