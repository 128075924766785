import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { useAppGlobalContext } from "../context/globalContext";
import { getLayoutName, getGridConfig } from "../Helpers";
import { DataGridCustomizer } from "../../../tables";

const useTabsWrapperContainer = () => {
	const {
		contentGrid,
		quote: { Tabs },
		ckeditor,
		handleCKEditorSelectedTabId,
		setSelectedTabsToDroppedTabs,
	} = useAppGlobalContext();
	const [data, setData] = useState(Tabs);
	const [change, setChange] = useState(false);
	const [duplicated, setDuplicated] = useState(false);
	const [layoutChanged, setLayoutChanged] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isTabDeleted, setIsTabDeleted] = useState(false);
	const [open, setOpen] = useState(getOpenTab());
	const [layoutsName, setLayoutsName] = useState(preparedState());
	const deleteModalRef = useRef();
	const marginModalRef = useRef();
	const newRecordRef = useRef();
	const priceSourceModalRef = useRef();

	const itemIds = useMemo(() => [], []);
	const isEditable = !app.currentQuote.IsLocked && !app.currentUser.IsReadOnly;
	const lastTab = data.length === 1;

	function getOpenTab() {
		const open_tab = sessionStorage.getItem("cpq_open_tab");
		if (open_tab === null) {
			return Tabs[0].IdQuoteTabs;
		}
		
		if(Tabs.find((tab) => tab.IdQuoteTabs === open_tab) === undefined) {
			return Tabs[0].IdQuoteTabs;
		}

		return open_tab;
	}

	function preparedState() {
		if (data.length) {
			let combinedData = {};
			data.forEach((tab) => {
				const tabId = tab.IdQuoteTabs;
				const layoutName = getLayoutName(tabId);
				combinedData = { ...combinedData, [tabId]: layoutName.ConfigurationName };
			});
			return combinedData;
		}
	}

	const confirmDeletion = useCallback(
		(itemToDelete) => {
			for (let i = 0; i < app.currentQuote.Items.length; i++) {
				if (app.currentQuote.Items[i].IdQuoteTabs == itemToDelete)
					itemIds.push(app.currentQuote.Items[i].IdQuoteItems);
			}

			const postProcessing = () => {
				setIsTabDeleted(true);
				setData(data.filter((item) => item.IdQuoteTabs !== itemToDelete));
			};

			const afterDelete = function (msg) {
				if (msg.error) {
					Dialog.open({
						title: "ERROR",
						message: msg.error,
						links: [
							{
								title: "OK",
								callback: function callback() {
									Dialog.close();
								}
							}
						]
					});
				} else {
					deleteModalRef.current.showModal(false);
				}
			};
			
			//TODO: Clean up once tab restore is released
			let deleteApi;
			if (app.settings.global?.EnableTabRestore) {
				deleteApi = quosal.api.tab.deleteTab(itemToDelete);
			} else {
				deleteApi = quosal.api.data.delete(
					[
						{
							table: "QuoteItems",
							where: [
								{
									field: "IdQuoteItems",
									operator: "In",
									value: itemIds
								}
							]
						},
						{
							table: "QuoteTabs",
							where: [
								{
									field: "IdQuoteTabs",
									operator: "Equals",
									value: itemToDelete
								}
							]
						}
					],
					app.currentQuote.IdQuoteMain
				);
			}

			deleteApi.finished = function (msg) {
				quosal.sell.quote.updateFromApiResponse(msg);
				afterDelete(msg);

				if (open === itemToDelete && app.currentQuote.Tabs.length !== 1) {
					if (
						open === app.currentQuote.Tabs[app.currentQuote.Tabs.length - 1].IdQuoteTabs
					) {
						updateOpenTab(app.currentQuote.Tabs[0].IdQuoteTabs)
						postProcessing();
					} else {
						const index = app.currentQuote.Tabs.findIndex(
							(item) => item.IdQuoteTabs === itemToDelete
						);
						updateOpenTab(app.currentQuote.Tabs[index + 1].IdQuoteTabs);
						postProcessing();
					}
				} else {
					postProcessing();
				}
				
				ckeditor?.execute?.("deleteProduct", itemToDelete);
				setSelectedTabsToDroppedTabs(app.currentQuuote);
				Dialog.setIsWorking(false);
				
			};
			Dialog.setIsWorking(true);
			deleteApi.call();
		},
		[ckeditor, data, itemIds, open]
	);

	const handleDeleteTab = (isShown, id) => {
		//TODO: Clean up once tab restore is released
		const enableTabRestore = app.settings.global?.EnableTabRestore;
		const retentionPeriod = app.settings.global.TabDeletionRetentionPeriod;
		const presetData = {
			lastItem: lastTab,
			titleText: lastTab ? "Unable to Delete Tab" : "Confirm Delete Tab",
			confirmationText: lastTab
				? "You can not delete the last tab on this quote."
				: enableTabRestore
				? "Are you sure you want to delete this tab? The tab can only be restored within " + retentionPeriod + " days after deletion."
				: "Are you sure you want to delete this tab?",
			deleteCallback: () => confirmDeletion(id)
		};
		deleteModalRef.current.showModal(isShown, presetData);
	};

	const handleDeleteGridRow = (isShown, rows, deleteCallback) => {
		const plural = rows?.length > 1 ? "s" : "";
		const presetData = {
			titleText: "Confirm Delete Item" + plural,
			confirmationText: `Are you sure you want to delete ${rows?.length} item${plural}?`,
			rows,
			deleteCallback: () => deleteCallback(rows)
		};
		deleteModalRef.current.showModal(isShown, presetData);
	};

	const fixTabOrder = useCallback(() => {
		const updates = [];
		const dataPreset = data;

		for (let i = 0; i < app.currentQuote.Tabs.length; i++) {
			const tabNumber = i + 1;
			if (dataPreset[i].TabNumber != tabNumber) {
				dataPreset[i].TabNumber = tabNumber;

				updates.push({
					fields: {
						TabNumber: tabNumber
					},
					queries: [
						{
							table: "QuoteTabs",
							where: [
								{
									field: "IdQuoteTabs",
									operator: "Equals",
									value: data[i].IdQuoteTabs
								}
							]
						}
					]
				});
			}
		}

		if (updates.length > 0) {
			const updateApi = quosal.api.data.update(updates, app.currentQuote.IdQuoteMain);
			updateApi.setFlag("fixTabOrder");
			updateApi.finished = function (msg) {
				quosal.sell.quote.updateFromApiResponse(msg);
			};
			updateApi.call();
			contentGrid.forceUpdate();
			return true;
		}
		return false;
	}, [contentGrid, data]);

	const handleMove = useCallback(
		(sourceId, targetId) => {
			const sourceIndex = data.findIndex((item) => item.id === sourceId);
			const targetIndex = data.findIndex((item) => item.id === targetId);
			const updatedData = [...data];

			const [removed] = updatedData.splice(sourceIndex, 1);
			updatedData.splice(targetIndex, 0, removed);

			setData(updatedData);
		},
		[data]
	);

	const updateOpenTab = (arg) => {
		setOpen(arg);
		handleCKEditorSelectedTabId(arg);
	};

	const gridCallback = useCallback(
		(LayoutName, tabId) => {
			setLayoutsName({ ...layoutsName, [tabId]: LayoutName });
			setLayoutChanged(layoutChanged);
		},
		[layoutChanged, layoutsName]
	);

	const openCustomizeGrid = useCallback(
		(tabId) => {
			const gridConfiguration = getGridConfig(tabId, layoutsName[tabId]);
			const showGridCustomizer = DataGridCustomizer.showDataGridCustomizer.bind(
				null,
				gridConfiguration,
				true,
				() => setChange(!change),
				false,
				tabId,
				() => setChange(!change),

			);
			DataGridCustomizer.executeTabsWrapperCallback = gridCallback;
			showGridCustomizer();
		},
		[change, gridCallback, layoutsName]
	);

	useEffect(() => {
		setData(Tabs);
	}, [Tabs]);

	useEffect(() => {
		if (!isTabDeleted)
		{
			fixTabOrder();
		}
	}, [data, fixTabOrder, isTabDeleted]);

	return {
		contentGrid,
		duplicated,
		isEditable,
		lastTab,
		loading,
		open,
		change,
		data,
		deleteModalRef,
		marginModalRef,
		newRecordRef,
		priceSourceModalRef,
		setData,
		updateOpenTab,
		setChange,
		setDuplicated,
		setLoading,
		handleDeleteTab,
		handleDeleteGridRow,
		handleMove,
		openCustomizeGrid
	};
};

export default useTabsWrapperContainer;
